import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { withGoogleMap, GoogleMap } from 'react-google-maps'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { withCookies, Cookies } from 'react-cookie';
import { ZoomInIcon, ZoomOutIcon } from 'icons'
import { ReduxState, setZoomLevel, setTooltip, setLastCheckedInfoWindowId, setBounds } from 'store'

import { googleMapsStyles } from '../../constants'
import { Markers, Pois, Polygons, Tooltip } from './lib'
import { Button } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons';

const StyledZoomControl = styled.div`
	z-index: 1;
	position: absolute;
	
	@media (min-width: 900px) {
		bottom: 1.5rem;
		right: 1.5rem;
	}
	
	@media (max-width: 900px) {
		bottom: 4.75rem;
		right: 1rem;
	}
	
	background: #fff;
	border-radius: 3px;
	padding: 10px;
	
	& > *:first-child {
		padding-bottom: 5px;
	}
`

const StyledCookie = styled.div`
	-webkit-box-shadow: 0 0 25px rgba(0,0,0,0.2);
	-moz-box-shadow: 0 0 25px rgba(0,0,0,0.2);
	-ms-box-shadow: 0 0 25px rgba(0,0,0,0.2);
	-o-box-shadow: 0 0 25px rgba(0,0,0,0.2);
	box-shadow: 0 0 25px rgba(0,0,0,0.2);
	font-weight: 700;
	text-align: left;
	z-index: 1000001;
	color: #000;
	background: rgb(255, 255, 255, 0.9);
	width: 100%;
    min-height: 30px;
    padding: 10px 0;
    position: fixed;
	bottom: 0;
	
	p {
		padding-left: 50px;
		padding-right: 50px;
		font-weight: 700 !important;
		font-size: 15px;
		line-height: 21px;
		font-family: arial,sans-serif!important;

		span {
			font-weight: 400;
			font-family: arial,sans-serif!important;

			a {
				color: #000;
				text-decoration: underline;
				font-family: arial,sans-serif!important;
			}
		}
	}

	button {
		color: #000 !important;
		font-size: 30px !important;
		width: 30px;
		height: 30px !important;
		position: absolute;
		top: 17px;
		right: 20px;
		padding: 0 !important;
		background-color: transparent !important;
		border-width: 0;

		span {
			font-size: 30px !important;
			display: block !important;
		}
	}
	
	@media (min-width: 900px) {

	}
	
	@media (max-width: 900px) {
		
	}
`

const StyledZoomControlButton = styled.div`
	cursor: pointer;
	width: 30px;
`

interface StateProps {
	travelTimes: ReduxState['travelTime']['travelTimes']
	tooltip: ReduxState['application']['tooltip']
	lastInfoWindowId: ReduxState['application']['lastInfoWindowId']
	geoJsonDataMarkers: ReduxState['application']['geoJsonDataMarkers']
}
interface DispatchProps {
	setZoomLevel: typeof setZoomLevel
	setTooltip: typeof setTooltip
	setLastCheckedInfoWindowId: typeof setLastCheckedInfoWindowId
	setBounds: typeof setBounds
}
interface Props { 
	canClickOnMap: boolean
	isCurrentlyAddingNewTravelTime: boolean
	changeisCurrentlyAddingNewTravelTime: ((value: boolean) => void)
	changeActiveStep: ((step: string) => void)
	instructionStepActive: string
}
type PropsUnion = StateProps & DispatchProps & Props & any

interface State {
	cookie: boolean,
	changeLng: boolean
}

export class Component extends React.Component<PropsUnion, State> {
	public readonly state: State = {
		cookie: this.props.cookies.get('cookie') === undefined ? true : false,
		changeLng: false
	}
	public mapRef = React.createRef<GoogleMap>()

	public setTooltipTimeout: ReturnType<typeof setTimeout> | null = null

	public shouldComponentUpdate(nextProps: Readonly<PropsUnion>, nextState: Readonly<State>, nextContext: any): boolean {
		if (this.mapRef.current && nextProps.travelTimes && nextProps.travelTimes !== this.props.travelTimes) {
			let north = 0
			let east = 0
			let south = 99
			let west = 99

			for (const coordinate of nextProps.travelTimes.map((v) => v.res.shapes.map((s) => s.shell)).flat(2)) {
				north = Math.max(north, coordinate.lat)
				east = Math.max(east, coordinate.lng)
				south = Math.min(south, coordinate.lat)
				west = Math.min(west, coordinate.lng)
			}

			const zoomLevel = Math.min(getBoundsZoomLevel(
				new google.maps.LatLngBounds(
					{ lat: south, lng: west },
					{ lat: north, lng: east }
				),
				{
					width: window.innerWidth,
					height: window.innerHeight
				}), 12)

			if (this.mapRef.current.getZoom() !== zoomLevel) {
				this.zoomTo(this.mapRef.current.getZoom(), zoomLevel, this.mapRef.current.getZoom() > zoomLevel ? 'out' : 'in')
			}

			this.mapRef.current.panTo({
				lat: (north + south) / 2,
				lng: (east + west) / 2
			})
		}
		if (this.state.changeLng != nextState.changeLng || this.props.t != nextProps.t) {
			return true
		}
		return false
	}

	public render() {
		const {t} = this.props
		const {cookie} = this.state
		const MapFactory = withGoogleMap((props: any) =>
			<GoogleMap
				ref={this.mapRef}
				clickableIcons={false}
				defaultZoom={10}
				defaultCenter={{
					lat: 52.3645568,
					lng: 4.8958031
				}}
				defaultOptions={{
					streetViewControl: false,
					scaleControl: false,
					mapTypeControl: false,
					zoomControl: false,
					rotateControl: false,
					fullscreenControl: false,
					disableDefaultUI: true,
					styles: googleMapsStyles,
					restriction: {
						latLngBounds: new google.maps.LatLngBounds({lat: 51.821762, lng: 2.961353}, {lat: 53.164610, lng: 7.079822}),
						strictBounds: false
					}
				}}
				onZoomChanged={() => {
					this.onZoomMap()
				}}
				onDblClick={() => this.setTooltipTimeout && clearTimeout(this.setTooltipTimeout)}
				onDragEnd={() => {
					const bounds = this.mapRef.current.getBounds()
					this.props.setBounds(bounds)
				}}
				onClick={(e) => {
					if (!this.props.canClickOnMap) return
					if (this.props.lastInfoWindowId != null) {
						this.props.setLastCheckedInfoWindowId(null);
					} else {

						if (this.props.tooltip) {
							this.setTooltipTimeout = setTimeout(() => {
								this.props.setTooltip(null)
							}, 250)
						} else {
							this.setTooltipTimeout = setTimeout(() => {
								const location = {
									lat: e.latLng.lat(),
									lng: e.latLng.lng()
								}
								const geocoder = new google.maps.Geocoder()
								geocoder.geocode({ location }, (results, status) => {
									const address = (results && results.length > 0 && results[0].address_components) || []

									const city = address.filter((a) => a.types.indexOf('locality') !== -1)[0]
									const streetName = address.filter((a) => a.types.indexOf('route') !== -1)[0]
									const number = address.filter((a) => a.types.indexOf('street_number') !== -1)[0]

									const addressString = ((streetName
										? streetName.short_name
										: '')
										+ (number
											? ' ' + number.short_name
											: '')
										+ (city
											? ', ' + city.short_name
											: '')) || t('Somewhere on a boat')

									if (addressString === t('Somewhere on a boat') || addressString.includes('Unnamed')) return;

									this.props.setTooltip({
										location,
										title: addressString
									})
								})
							}, 250)
						}
					}
				}}
			>
				{this.renderZoomControls()}
				<Markers 
					changeZoom={this.changeZoom}
					changeCenter={this.changeCenter}
					onZoomMap={this.onZoomMap}
				/>
				<Pois />
				<Polygons />
				<Tooltip 
					changeisCurrentlyAddingNewTravelTime={this.props.changeisCurrentlyAddingNewTravelTime}
					isCurrentlyAddingNewTravelTime={this.props.isCurrentlyAddingNewTravelTime}
					changeActiveStep={this.props.changeActiveStep}
					instructionStepActive={this.props.instructionStepActive}
				/>
				{cookie ?
					(<StyledCookie>
					<div>
						<p
							dangerouslySetInnerHTML={
							{__html: t('cookieBar', {interpolation: {escapeValue: false}})}}>
							</p>
						<Button 
							icon={<CloseCircleOutlined />}
							onClick={() => {  this.props.cookies.set('cookie', false, {path: '/'}); this.setState({cookie: false, changeLng: !this.state.changeLng}); this.forceUpdate(); }}
						>
						</Button>
					</div>
				</StyledCookie>)
				:
				null}
			</GoogleMap>
		)

		return (
			<MapFactory
				containerElement={<div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }} />}
				mapElement={<div style={{ height: `100%` }} />}
			/>
		)
	}

	onZoomMap = () => {
		const zoomLvl = Math.round(this.mapRef.current.getZoom())
		const bounds = this.mapRef.current.getBounds()
		this.props.setBounds(bounds)
		this.props.setZoomLevel(zoomLvl)
	}

	changeCenter = (center) => {
		this.mapRef.current.panTo(center)
	}

	changeZoom = (lvl) => {
		this.mapRef.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(lvl)
	}

	private renderZoomControls() {
		return (
			<StyledZoomControl>
				<StyledZoomControlButton onClick={() => this.zoom('in')}>
					<ZoomInIcon />
				</StyledZoomControlButton>
				<StyledZoomControlButton onClick={() => this.zoom('out')}>
					<ZoomOutIcon />
				</StyledZoomControlButton>
			</StyledZoomControl>
		)
	}

	private zoom = (zoomDirection: 'in' | 'out') => {
		if (!this.mapRef.current) return
		const currentZoom = this.mapRef.current.getZoom()
		if (currentZoom < 7 && currentZoom > 15) return
		this.zoomTo(currentZoom, zoomDirection === 'in' ? currentZoom + 1 : currentZoom - 1, zoomDirection)
	}

	private zoomTo = (currentZoom: number, endStop: number, zoomDirection: 'in' | 'out') => {
		if (
			!this.mapRef.current ||
			(zoomDirection === 'in' && currentZoom >= endStop) ||
			(zoomDirection === 'out' && currentZoom <= endStop)
		) return


		const nextZoom = Math.round((currentZoom + (zoomDirection === 'in'
			? + 0.2
			: - 0.2)) * 10) / 10

		this.mapRef.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(nextZoom)

		setTimeout(() => {
			this.zoomTo(nextZoom, endStop, zoomDirection)
		}, 25)
	}
}

function getBoundsZoomLevel(bounds: google.maps.LatLngBounds, mapDim: { width: number, height: number }): number {
	const WORLD_DIM = { height: 256, width: 256 };
	const ZOOM_MAX = 21;

	function latRad(lat: number) {
		const sin = Math.sin(lat * Math.PI / 180);
		const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
		return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
	}

	function zoom(mapPx: number, worldPx: number, fraction: number) {
		return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
	}

	const ne = bounds.getNorthEast();
	const sw = bounds.getSouthWest();

	const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

	const lngDiff = ne.lng() - sw.lng();
	const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

	const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
	const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

	return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

const mapStateToProps = (state: ReduxState) => ({
	travelTimes: state.travelTime.travelTimes,
	tooltip: state.application.tooltip,
	lastInfoWindowId: state.application.lastInfoWindowId,
	geoJsonDataMarkers: state.application.geoJsonDataMarkers
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	setZoomLevel,
	setTooltip,
	setLastCheckedInfoWindowId,
	setBounds
}, dispatch)

const MapCookies = withCookies(Component) as any

const MapTranslation = withTranslation()(MapCookies)

export const Map = connect<StateProps, DispatchProps, Props, ReduxState>(
	mapStateToProps,
	mapDispatchToProps
)(MapTranslation)
