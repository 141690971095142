import React from 'react'
import {Row, Col, Card, Alert} from 'antd'
import {LogoIcon, MvaIcon, ClearstoneIcon, MvaLogo} from 'icons'
import styled from 'styled-components'
import {withTranslation} from 'react-i18next'
// tslint:disable-next-line: no-submodule-imports
import 'antd/lib/message/style'
import i18n from "../../../i18n/i18next";
import { CloseOutlined } from '@ant-design/icons';
import { withCookies, Cookies } from 'react-cookie';
import queryString from "query-string";
import { confirmEmail } from "../../../utils/confirmEmail";
import {getHistory} from '../../../utils/history'

const CenteredDiv = styled.div`
	position: absolute;
	width: 100%;
`

const MarginSpan = styled.span`
margin: 0px 10px
`

const StyledLogoContainer = styled.div`
	z-index: 10;
`
const StyledLogoContainerIndividual = styled.div`	
	z-index: 10;
	margin-left:10rem;
	padding-left:10rem;
`

interface State {
}


class Component extends React.Component<any, State> {

	componentDidMount(){document.body.style.overflow='auto'}
	componentWillUnmount(){document.body.style.overflow='hidden'}

	public render() {
		const {t} = this.props
		return (
			<CenteredDiv>
				<Row gutter={[16, 16]} justify="center" align="top">
					<Col sm={24} md={12} lg={12} xl={8} className="text-center login-col">
						<Card className="login-card email">
							<div className="login-logo">
								<MvaLogo/>
							</div>
							<Alert 
								className="text-left"
								message={t('emailNotConfirmed.failMessage')}
								description={t('emailNotConfirmed.failDescription')}
								type="error"
								showIcon
							/>
							<p className="text-left" dangerouslySetInnerHTML={
									{__html: t('emailNotConfirmed.mainText', {interpolation: {escapeValue: false}})}
							}></p>
						</Card>
						<div className="login-logos">
							<span>{t('Homelocator brought to you by')}:</span>
							<StyledLogoContainer>
								<MvaIcon />
								<LogoIcon/>
								<ClearstoneIcon/>
							</StyledLogoContainer>
							<span 
								style={{'fontWeight': 'normal', 'textTransform': 'none', 'fontSize': '12px'}}
								dangerouslySetInnerHTML={
									{__html: t('Based on the open-source MapitOut application', {interpolation: {escapeValue: false}})}
								}
								>									
							</span>
						</div>
					</Col>
				</Row>
			</CenteredDiv>
		)
	}

	private changeLng = (lng) => {
	  i18n.changeLanguage(lng)
  }

}

const MailCookies = withCookies(Component) as any

const MailPageFail = withTranslation()(MailCookies)

export {MailPageFail}
