import { applyMiddleware, createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
// tslint:disable-next-line: no-submodule-imports
import storage from 'redux-persist/lib/storage'

import { reducer as applicationReducer, State as ApplicationState } from './application/reducer'
import { reducer as travelTimeReducer, State as TravelTimeState } from './travel-time/reducer'
import userReducer from '../store/user/reducers'

export interface ReduxState {
 application: ApplicationState
 travelTime: TravelTimeState,
 userReducer: any,
}

const persistConfig = {
	version: 1,
	key: 'mapitout',
	storage,
	blacklist: [
		'application'
	]
}

const rootReducer = combineReducers({
	application: applicationReducer,
	travelTime: travelTimeReducer,
	user: userReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const configureStore = () => {
	let store
	// TODO Nikola look into this
	if (process.env.NODE_ENV === 'production') {
		store = createStore(persistedReducer, applyMiddleware(thunk))
	} else {
		store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))
	}

	let persistor = persistStore(store)

	return { store, persistor }
}
