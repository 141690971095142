import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter, Route } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'

// @ts-ignore
import scriptjs from 'scriptjs'
import dotenv from 'dotenv'

import { configureStore } from 'store'
import { App } from 'containers'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'flexibility/flexibility'

import { PrivateRoute } from './containers/OidcComponent/route'
import { Callback } from './containers/OidcComponent/callback'
import { Logout } from './containers/OidcComponent/logout'
import { LogoutCallback } from './containers/OidcComponent/logoutCallback'
import { SilentRenew } from './containers/OidcComponent/silentRenew'
import OidcComponent from './containers/OidcComponent/'
import { GlobalHistory } from './utils/history'
import { IndexPage } from './containers/app/indexPage'
import { BuyPage } from './containers/app/buyNow'
import { PageBrokers } from './containers/app/pageBrokers'
import { IDENTITY_CONFIG } from './containers/OidcComponent/oidcsettings'
import { ThankYouRegistration } from "./containers/app/thankYou/registration";
import { ThankYouOrder } from "./containers/app/thankYou/order";
import { MailPage } from "./containers/app/confirmMail";
import { MailPageSuccess } from "./containers/app/emailConfirmed";
import { MailPageFail } from "./containers/app/emailNotConfirmed";
import { MailPageAlreadyConfirmed } from "./containers/app/emailAlreadyConfirmed";
import { AccountCreated } from "./containers/app/accountCreated";
import { SignIn } from "./containers/app/signIn";
import { NoSub } from './containers/app/subs/noSub'
import { Expired } from './containers/app/subs/expired'
import { Cancelled } from './containers/app/payment/cancelled'
import { Failed } from './containers/app/payment/failed'
import { Pending } from './containers/app/payment/pending'
import { CompaniesPage } from "./containers/app/companies";

import './assets/fonts/HelveticaNeueLTProCn.otf'
import './assets/fonts/HelveticaNeueLTProBdCn.otf'
import './assets/fonts/HelveticaNeueLTProBlkCn.otf'

import { inIframe } from './utils/inIframe'
import './i18n/i18next'
import i18n from "./i18n/i18next";

if (inIframe()) {
	import('./iFrameCss')
	const url = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href
} else {
	import('./normalCss')
}

dotenv.config()

const { store, persistor } = configureStore()

export {store}


class Root extends React.Component<{}, {loaded: boolean}> {
	public readonly state = {
		loaded: false
	}
	public componentDidMount(): void {
		scriptjs(`https://maps.googleapis.com/maps/api/js?v=3&key=${process.env.REACT_APP_GOOGLE_MAPS_TOKEN}&v=3.exp&libraries=geometry,drawing,places`, () => this.setState({loaded: true}))
		// IDENTITY_CONFIG.ui_locales = i18n.language == 'en' ? 'en-GB' : i18n.language 
	}

	public render() {
		if (!this.state.loaded) { return null }

		return (
			<Suspense fallback={(<div>Loading</div>)}>
				<CookiesProvider>
					<Provider store={store}>
						<BrowserRouter>	
						{/* <IndexPage /> */}
						{/* <Route exact path='/pages/companies' component={CompaniesPage} /> */}
						<Route exact path='/pages/companies' 
							component={() => {
								window.location.href = 'https://www.mva.nl/zoek-een-makelaar'
								return null
							}} 
						/>
							<OidcComponent>
								<PersistGate loading={null} persistor={persistor}>
										{/* <Map /> */}
										<Route exact path='/' component={IndexPage} />
										<Route exact path='/buy-now' component={BuyPage} />
										<Route exact path='/brokers' component={PageBrokers} />
										<Route exact path='/thank-you' component={ThankYouRegistration} />
										<Route exact path='/thank-you-order' component={ThankYouOrder} />
										<Route exact path='/account-created' component={AccountCreated} />
										<Route exact path='/confirm-account' component={MailPage} />
										<Route exact path='/confirm-account/success' component={MailPageSuccess} />
										<Route exact path='/confirm-account/fail' component={MailPageFail} />
										<Route exact path='/confirm-account/already-confirmed' component={MailPageAlreadyConfirmed} />
										<Route exact path='/subscription/nosubscription' component={NoSub} />
										<Route exact path='/subscription/expired' component={Expired} />
										<Route exact path='/payment/cancelled' component={Cancelled} />
										<Route exact path='/payment/failed' component={Failed} />
										<Route exact path='/payment/pending' component={Pending} />
										<Route exact path='/signin' component={SignIn} />
										<GlobalHistory />
										<Route exact path='/signin-oidc' component={Callback} />
										<Route exact path='/logout' component={Logout} />
										<Route exact path='/logout/callback' component={LogoutCallback} />
										<Route exact path='/silentrenew' component={SilentRenew} />
										<PrivateRoute exact component={App} path='/app/:travelOne?/:travelTwo?/:travelThree?/:travelFour?/:travelFive?/:travelSix?'/>
								</PersistGate>
							</OidcComponent>
						</BrowserRouter> 
					</Provider>
				</CookiesProvider>
			</Suspense>
		)
	}
}

ReactDOM.render(<Root/>, document.getElementById('root'))
