import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'

const CenteredDiv = styled.div`
  position: fixed;
  background: rgba(255,255,255,1);
  z-index: 999;
  width: 100%;
  height: 100%;
`

export default class Component extends React.Component<any, {}> {
  public render() {
    return (
      <CenteredDiv>
        <Spin size="large" tip="Loading..." />
      </CenteredDiv>
    )
  }
}