import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components'

import { ReduxState, setGeoJsonData, setGeoJsonDataMarkers, setLegend } from 'store'
import { ClockIcon, CrossIcon, EditIcon } from 'icons'
import { TravelType } from 'enums'
import { getTravelTypeInfo, hexColorToRGBA } from 'utils'
import { shadows } from '../../../constants'
import { TravelTimeStored, TravelTimeAbstraction, TravelTimeBody, TravelTimeResponse } from '../../../interfaces'
import moment from 'moment'
import { Loader } from './Loader'
import { getToken } from '../../../utils/oAuth'
import { withTranslation } from 'react-i18next'

const StyledTravelCardContainer = styled.div<{ color: string }>`
	border-radius: 9px;
	border: 3px solid rgba(0,0,0,1);
	background-color: ${(props) => hexColorToRGBA(props.color, .9)};
	color: #fff;
	${shadows.normal};
	
	@media (min-width: 900px) {
		display: block;
		flex-direction: row;
		margin-bottom: 16px;
	}
	
	@media (max-width: 900px) {
		width: auto;
		display: block;
		align-items: center;
		justify-content: center;
		margin-bottom: .5rem;
	}
`

const StyledTravelCard = styled.div`
@media (min-width: 900px) {
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
	background-color: rgba(0,0,0,1);
    padding: 4px 6px 4px 9px;
}

@media (max-width: 900px) {
	height: 2.5rem;
	width: auto;
	display: flex;
	align-items: center;
	margin-bottom: .5rem;
	margin-bottom: 16px;
    background-color: rgba(0,0,0,1);
    padding: 4px 6px 4px 9px;
}
`

const StyledTravelCardInfo = styled.div`
	flex: 1;

	@media (min-width: 900px) {
		padding: 0;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
	
	h1 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 18px;
		color: #ffffff;
		font-weight: 400;
		font-family: HelveticaNeue, sans-serif !important;
		margin: 0;
		
		@media (max-width: 900px) {
			display: block;
		}
	}
`

const StyledTravelCardInfoMeta = styled.div`
	@media (min-width: 900px) {
		display: flex;
		flex-direction: row;
	}
`

const StyledTravelCardInfoMetaItem = styled.div`	
	@media (min-width: 900px) {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	&:not(:last-child) {
		margin-right: 2rem;
		
		@media (max-width: 900px) {
			display: none;
		}
	}
	@media (max-width: 900px) {
		&:last-child {
			p {
				display: none;
			}
		}
	}
`

const StyledTravelCardInfoMetaItemIcon = styled.div`
	width: 1rem;
	height: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	
	@media (min-width: 900px) {
		margin-right: .5rem;
	}
`

const StyledTravelCardAction = styled.div`
	padding: 0;
	
	@media (max-width: 900px) {
		display: block;
	}
`

const StyledTravelCardActionItem = styled.div`
	cursor: pointer;
	position: relative;
	width: 21px;
	height: 21px;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, .1);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2px;
	
	:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, .1);
		border-radius: 50%;
		width: 100%;
		height: 100%;
		transition: 80ms;
	}
	:hover:before {
		transform: scale(1.25);
	}
	
	svg {
		width: 19px;
    	height: 19px;
	}
	
	&:not(:last-child) {
		margin-bottom: .5rem;
	}
`

const StyledFilterOptionContainer = styled.div`
	@media (min-width: 900px) {

	}

	@media (max-width: 900px) {
		
	}
	display: block;
	flex-direction: row;
	align-items: center;
	padding-bottom: 1rem;
	padding-left: 0.6rem;
	padding-right: 0.2rem;
`

const StyledFilterOption = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: .75rem;
	
	:not(:last-child) {
		margin-right: .5rem;
	}
	
	p {
		margin-left: .5rem !important;
		font-size: 15px;
		height: 17px;
		line-height: 17px;
	}
`

const StyledToggle = styled.label`
	position: relative;
	display: block;
	width: 30px;
	height: 17px;
	flex-shrink: 0;
`

const StyledToggleInput = styled.input`
	opacity: 0;
	width: 0;
	height: 0;
`

const StyledToggleVirtual = styled.span<{ color: string }>`
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #d8d8d8;
	transition: 200ms;
	border-radius: 99px;
	
	:disabled {
		opacity: .5;
		cursor: auto;
	}
	
	:before {
		position: absolute;
		content: '';
		height: 15px;
		width: 15px;
		top: 1px;
		left: 2px;
		background-color: #fff;
		transition: 200ms;
		border-radius: 99px;
	}
	
	${StyledToggleInput}:checked + & {
		background-color: ${(props) => hexColorToRGBA(props.color)};
		
		:before {
			transform: translateX(11px);
		}
	}
`

const StyledLabel = styled.p<{ disabled: boolean }>`	
	opacity: .5;
	color: black;
	margin-left: 0.75rem;
	opacity: 1;
`
const Square = styled.p<{ color: string }>`		
	height: 1.50rem;
	width: 1.50rem;
	margin: .25rem;
	
	background-color: ${(props) => hexColorToRGBA(props.color)};
`


interface StateProps { }
interface DispatchProps {
	setGeoJsonData: typeof setGeoJsonData,
	setGeoJsonDataMarkers: typeof setGeoJsonDataMarkers,
	setLegend: typeof setLegend
}
interface Props {
	color: string
	title: string
	value: string
	onDelete: () => any
	onEdit: () => any,
	travelTimes: TravelTimeStored[] | null
}
type PropsUnion = StateProps & DispatchProps & Props & any

interface State {
	loading: boolean,
	data: any[],
	travelTimeResponse: any[],
	legend: string[]
}

export class Component extends React.Component<PropsUnion, State> {
	public readonly state: State = {
		loading: true,
		data: [],
		travelTimeResponse: [],
		legend: []
	}

	public render() {
		const { color, title, value, onDelete, onEdit } = this.props

		const loading = this.state.loading;
		return (
			<>
				{loading ? <Loader /> :
					<StyledTravelCardContainer color={color}>
						<StyledTravelCard>
							<StyledTravelCardInfo>
								<h1>{title}</h1>
							</StyledTravelCardInfo>
							<StyledTravelCardAction>
								<StyledTravelCardActionItem onClick={onDelete}>
									<CrossIcon />
								</StyledTravelCardActionItem>
							</StyledTravelCardAction>
						</StyledTravelCard>

						<StyledFilterOptionContainer>
							{this.renderButtons()}
						</StyledFilterOptionContainer>

					</StyledTravelCardContainer>
				}
			</>

		)
	}

	renderButtons() {
		var a = this;
		return (
			<>
				{
					this.state.data.map(function (item) {
						if(item.bovengrens === -1) {
							return (
								<StyledFilterOption key={item.id}>
								<StyledToggle>
									<StyledToggleInput
										id={item.id}
										type='checkbox'
										defaultChecked={true}
										disabled={false}
										onChange={(e) => {
											a.changeLegend(e.target.id, e.target.checked)
										}}
									/>
									<StyledToggleVirtual color={item.rgb} />
								</StyledToggle>
									<StyledLabel disabled={false}>
										{a.props.t('rent')}
									</StyledLabel>
								</StyledFilterOption>
							)
						} else {
							return (
								<StyledFilterOption key={item.id}>
									<StyledToggle>
										<StyledToggleInput
											id={item.id}
											type='checkbox'
											defaultChecked={true}
											disabled={false}
											// onChange={(e) => this.props.setSecondaryEducationVisibility(e.target.checked)}
											onChange={(e) => {
												a.changeLegend(e.target.id, e.target.checked)
											}}
										/>
										<StyledToggleVirtual color={item.rgb} />
									</StyledToggle>
									<StyledLabel disabled={false}>{
										new Intl.NumberFormat('nl-NL', {
											style: 'currency',
											currency: 'EUR',
											maximumFractionDigits: 0,
											minimumFractionDigits: 0,
										}).format(item.ondergrens)
									}
										&nbsp;-&nbsp;
										{ 	(item.bovengrens === 99999999 && a.props.t('99999999'))
											||
											new Intl.NumberFormat('nl-NL', {
												style: 'currency',
												currency: 'EUR',
												maximumFractionDigits: 0,
												minimumFractionDigits: 0,
											}).format(item.bovengrens)
										}</StyledLabel>
								</StyledFilterOption>
							)
						}
					})
				}
			</>
		)
	}

	public changeLegend = (id: string, checked: boolean) => {
		if (checked) {
			if (!this.state.legend.includes(id)) {
				var legends = this.state.legend.map((v) => {
					return v;
				});
				legends.push(id);
				this.props.setLegend(legends);
				this.setState({
					legend: legends
				});
			}
		} else {
			if (this.state.legend.includes(id)) {
				var legends: string[] = [];
				this.state.legend.map((v) => {
					if (v != id)
						legends.push(v);
				});
				this.props.setLegend(legends);
				this.setState({
					legend: legends
				});
			}
		}
	}

	componentDidMount() {
		fetch(process.env.REACT_APP_API_BASE_URL + "/wonen/marktwaarden/klasseIndelingen?woningType=" + this.props.value, {
			headers: {
				'Authorization': `Bearer ${getToken()}`,
			}
		})
			.then(res => res.json())
			.then(
				(result) => {
					var legend = result.data.marktwaardenKlassen.map((v) => {
						return v.id;
					});
					this.props.setLegend(legend);
					this.setState({
						data: result.data.marktwaardenKlassen,
						legend: legend
					});
				},
				(error) => {
				}
			);


		const travels: TravelTimeAbstraction[] = (this.props.travelTimes || []).map((v) => {
			return {
				title: v.title,
				location: {
					lat: v.location.lat,
					lng: v.location.lng,
				},
				duration: v.duration,
				transport: v.transport
			}
		});

		const travelsWithIds: Array<TravelTimeAbstraction & { id: string }> = travels.map((travel, i) => ({
			...travel,
			id: i.toString()
		}))

		const departure_time = moment() // Get the current data
			.utc() // Remove timezone details by setting it to UTC
			.day(8) // Set the date to the date of next monday
			.hour(9).minute(0).second(0).millisecond(0) // Set the time to 9:00 (AM)
			.format() // Turn the moment object into a string

		const data: TravelTimeBody = {
			departure_searches: travelsWithIds.map((travel, i) => ({
				id: travel.id,
				coords: travel.location,
				departure_time,
				travel_time: travel.duration,
				transportation: {
					type: travel.transport
				}
			})),
			intersections: [
				{
					id: 'intersection',
					search_ids: []
				}
			],
			unions: [
				{
					id: 'union',
					search_ids: []
				}
			]
		}

		data.intersections[0].search_ids = data.departure_searches.map((search) => search.id)
		data.unions[0].search_ids = data.departure_searches.map((search) => search.id)

		fetch(process.env.REACT_APP_API_BASE_URL + "/traveltime/v4/time-map", {
			method: "POST",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${getToken()}`,
			},
			body: JSON.stringify(data)
		})
			.then(res => res.json())
			.then(
				(result) => {
					result.travelTimeMap = {
						results: result.results
					};
					delete result.results;
					result.woningType = this.props.value;

					fetch(process.env.REACT_APP_API_BASE_URL + "buurten/select/byTravelTimeMap", {
						method: "POST",
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${getToken()}`,
						},
						body: JSON.stringify(result)
					})
						.then(res1 => res1.json())
						.then(
							(res2) => {
								this.props.setGeoJsonData(res2.data);
								this.setState({
									loading: false
								})
							},
							(error) => {
							}
						);



					fetch(process.env.REACT_APP_API_BASE_URL + "aanbod/select/byTravelTimeMap", {
						method: "POST",
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${getToken()}`,
						},
						body: JSON.stringify(result)
					})
						.then(res1 => res1.json())
						.then(
							(res2) => {
								this.props.setGeoJsonDataMarkers(res2)
							},
							(error) => {
							}
						)
				},
				(error) => {
				}
			)
	}
}


const mapStateToProps = (state: ReduxState) => ({
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	setGeoJsonData,
	setGeoJsonDataMarkers,
	setLegend
}, dispatch)

const MTeCardTranslation = withTranslation()(Component)

export const MTeCard = connect<StateProps, DispatchProps, Props, ReduxState>(
	mapStateToProps,
	mapDispatchToProps
)(MTeCardTranslation)
