import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import styled, { css } from 'styled-components'
import Select from 'react-select'
import { withTranslation } from 'react-i18next'

import { ReduxState } from 'store'
import { TravelTimeAbstraction, MTeHouseTypeSelectedAbstraction } from 'interfaces'
import { TravelType } from 'enums'
import { getTravelTypeInfo, hexColorToRGBA } from 'utils'
import { shadows } from '../../../constants'
import { DropdownIcon, GoIcon, TrashcanIcon } from 'icons'
import { InfoCircleOutlined } from '@ant-design/icons'
import { getToken } from '../../../utils/oAuth'
import { Modal, Button } from 'antd'

// Component container styling
const StyledContainer = styled.div`	
	@media (min-width: 900px) {
		display: flex;
		flex-direction: row;
		background-color: #fff;
		border-radius: 9px;
		padding-left: 1rem;
		height: 60px;
		align-items: center;
		width: 510px;
		${shadows.normal};
		border: 3px solid rgba(23,163,69,1);
	}
	@media (max-width: 900px) {
		display: flex;
		flex-direction: column;
	}
`

// Title wrapper
const StyledTitle = styled.div`
	p {
		margin: 0;
		font-family: HelveticaNeueBold, sans-serif !important;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 22px;
		color: rgba(0,0,0,0.7);
	}
	@media (min-width: 900px) {
		white-space: nowrap;
		display: flex;
		align-items: flex-end;
		height: 1.5rem;
		margin-bottom: 2px;
		margin-right: 1rem;
		p {
			font-size: 16px;
		}
    }
    @media (max-width: 900px) {
    	text-align: center;
    	margin-bottom: 2rem;
		font-size: 1.25rem;
		p {
			font-size: 22px;
		}
    }
`

// Wrapper for the input fields
const StyledSegment = styled.div<{ withoutRightMargin?: boolean }>`
	display: flex;
	flex-direction: column;
	
	@media (min-width: 900px) {
		${(props) => !props.withoutRightMargin && css`
			margin-right: 1rem;
		`}
	}
	@media (max-width: 900px) {
    	padding: 1rem 1.5rem;
    	border-radius: 99px;
    	background: #fff;
    	margin-bottom: .5rem;
    }
`

// A colored label
const StyledLabel = styled.label<{ color: string }>`
	color: ${(props) => props.color};
`

/*
 * Styling wrapper for the `react-select` package, contains duplicate styling rules from:
 * 	- `StyledAddressInput`
 * 	- `StyledAutocompleteDropdownContainer`
 * 	- `StyledAutocompleteSuggestion`
 * 	merging these rules would mean a lot of overhead in parameter usage (minor differences)
 */
const StyledSelect = styled(Select) <{ color: string, minWidth: number }>`
	.react-select {
		position: relative;
		
		&__control {
			cursor: pointer;
			display: flex;
			flex-direction: row;
			background: #fff;
			min-width: ${(props) => props.minWidth}px;
			height: 1.5rem;
			
			@media (min-width: 900px) {
				border-bottom: 2px solid #d8d8d8;
				
				&--is-focused, &--menu-is-open {
					border-bottom: 3px solid #d8d8d8;
					/* // Fixes the height difference created by the focus effect -> prevents elements from jumping within the layout */
					margin-bottom: -1px;
				}
			}
		}
		&__value-container {
			padding-left: 0;
			padding-right: 0;
		}
		&__single-value {
			margin: 0;
			font-family: HelveticaNeue, sans-serif !important;
			font-weight: 700;
			text-transform: lowercase;
			color: rgba(0,0,0,0.4);
		}
		&__menu {
			position: absolute;
			top: 100%;
			min-width: 300px;
			margin-top: 1rem;
			${shadows.normal};
			border-radius: 10px;
			background-color: #fff;
			overflow: hidden;
			z-index: 10;
		}
		&__menu-list {
			padding: 0;
		}
		&__option {
			position: relative;
			cursor: pointer;
			transition: background-color 80ms;
			padding: 10px 5px;
			overflow: auto;
			
			:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 0;
				background-color: ${(props) => props.color}
			}
			&--is-focused, &--is-selected {					
				:before {
					width: 2px;
				}
			}
			
			&--is-focused {
				background: ${(props) => `linear-gradient(to right,
					${hexColorToRGBA(props.color, 0.25)},
					${hexColorToRGBA(props.color, 0.1)}
					)`};
			}
			
			&--is-selected {
				color: inherit;
				background: ${(props) => `linear-gradient(to right,
					${hexColorToRGBA(props.color, 0.4)},
					${hexColorToRGBA(props.color, 0.2)}
					)`};
			}
		}
	}
`

// Container for the actions
const StyledActionContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`

// Single action
const StyledActionButton = styled.div<{ color: string, isDisabled?: boolean, onlyVisibleOnMobile?: boolean }>`
	${(props) => props.onlyVisibleOnMobile && css`
		@media (min-width: 900px) {
			display: none;
		}
	`};
	cursor: ${(props) => props.isDisabled ? 'default' : 'pointer'};
	position: relative;
	background-color: ${(props) => props.color};
	opacity: ${(props) => props.isDisabled ? .5 : 1};
	border-radius: 99px;
	height: 44px;
	width: 44px;
	margin: 8px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 100ms;
	z-index: 0;
	
	${(props) => !props.isDisabled && css`
		:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 99px;
			background-color: ${hexColorToRGBA(props.color, .5)};
			transition: 100ms;
			z-index: -1;
		}
		:hover:before {
			transform: scale(1.2);
		}
	`};
`

const StyledCancelButtonContainer = styled.div`
	@media (min-width: 900px) {
		display: inline-block;
	}
	@media (max-width: 900px) {
		text-align: center;
	}
`

// Cancel button styles
const StyledCancelButton = styled.div`
	cursor: pointer;
	margin: 1rem 0;
	color: rgba(0,0,0,.45) !important;
	border-radius: 99px;
	padding: .3rem 1.8rem;
	background-color: #ffffff;
	border: 2px solid rgba(0,0,0,0.45);
	font-size: 20px !important;
	font-family: HelveticaNeueMedium,sans-serif !important;

	@media (max-width: 900px) {
		display: inline-block;
		text-align: center;
}
`

interface StateProps { }
interface DispatchProps { }
interface Props {
	onFinish: (v: MTeHouseTypeSelectedAbstraction) => any,
	onCancel: () => any
}
type PropsUnion = StateProps & DispatchProps & Props & any
type Entry = { value: string, label: string }

interface State {
	housingTypes: any[],
	selected: Entry | null
	showHouseModal: boolean
}

export class Component extends React.Component<PropsUnion, State> {
	constructor(props: PropsUnion) {
		super(props)

		this.state = {
			housingTypes: [],
			selected: null,
			showHouseModal: false
		}
	}

	componentDidMount() {
		this.getData()
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		// console.log(prevProps.t)
		// console.log(this.props.t)
		if (prevProps.t !== this.props.t){
			this.getData()
		}
	}


	public render() {
		const {t} = this.props
		return (
			<>
				<StyledContainer>
					<StyledTitle>
						<p>{t('Which type of house?')}</p>
					</StyledTitle>
					{this.renderDropdowns()}
					<StyledActionContainer>
						<StyledActionButton
							color={'#ff404b'}
							onClick={() => {
								this.isValid(this.state.selected) && this.props.onFinish({
									title: this.state.selected != null ? this.state.selected.label : '',
									value: this.state.selected != null ? this.state.selected.value : ''
								})
								this.props.setStep('3')
							}}
						>
							<GoIcon />
						</StyledActionButton>
					</StyledActionContainer>
				</StyledContainer>
				<StyledCancelButtonContainer>
					<StyledCancelButton className='label' onClick={() => { this.props.onCancel() }}>{t('cancel')}</StyledCancelButton>
				</StyledCancelButtonContainer>
			</>
		)
	}

	public renderDropdowns() {
		const {t} = this.props
		var options: Entry[] = [];
		if (this.state.housingTypes.length > 0) {
			for (var i = 0; i < this.state.housingTypes.length; i++) {
				options.push(
					{ value: this.state.housingTypes[i].id, label: this.state.housingTypes[i].weergaveNaam }
				)
			}
		}
		const houseTypes = t('HouseTypes', {returnObjects: true})
		const currentOption = options.filter((t) => t.value === (this.state.selected != null ? this.state.selected.value : ''))[0]
		return (
			<>
				<Modal
					title={t('Show houses info')}
					width={'90%'}
					onOk={() => this.setState({showHouseModal: false})}
					onCancel={() => this.setState({showHouseModal: false})}
					visible={this.state.showHouseModal}
					cancelButtonProps={null}
				>
					{
						Object.keys(houseTypes).map(k => {
							return (
								<span>
									<h3>{houseTypes[k].Title}</h3>
									<p>{houseTypes[k].Description}</p>
								</span>
							)
						})
					}
				</Modal>
				<StyledSegment withoutRightMargin={true}>
					<StyledLabel className='label' as='p' color='#df0030'>
						{t('House Type')}
						<InfoCircleOutlined 
							onClick={() => this.setState({showHouseModal: true})}
							style={{marginLeft: '8px'}}
						/>
					</StyledLabel>
					<StyledSelect
						placeholder={t('select')}
						styles={{ control: () => null }}
						classNamePrefix='react-select'
						minWidth={255 }
						value={currentOption}
						onChange={(v: Entry) => v && 'value' in v && this.setState({
							selected: v
						})}
						onBlur={() => window.scrollTo(0, 0)}
						options={options}
						components={{
							DropdownIndicator: () => <DropdownIcon style={{ marginLeft: 10 }} />,
							IndicatorSeparator: null
						}}
						color='#df0030'
					/>
				</StyledSegment>
			</>
		)
	}

	private isValid(entry: Entry | null): boolean {
		return (entry != null)
	}

	private getData(): void {
		let lng = localStorage.getItem('i18nextLng') 
		if (lng === 'en')
			lng = 'en-GB'
		if (lng === 'nl- NL')
			lng = 'nl-NL'
		fetch(process.env.REACT_APP_API_BASE_URL + '/wonen/marktwaarden/woningTypen?woningtypeCategorie=mva&language=' + lng, {
			headers: {
				'Authorization': `Bearer ${getToken()}`,
			}
		})
			.then(res => res.json())
			.then(
				(result) => {
					this.setState({
						housingTypes: result.data.woningTypen
					});
				},
				(error) => {
				}
			)
	}
}

const mapStateToProps = (state: ReduxState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

const MTeOverlayTranslation = withTranslation()(Component)

export const MTeOverlay = connect<StateProps, DispatchProps, Props, ReduxState>(
	mapStateToProps,
	mapDispatchToProps
)(MTeOverlayTranslation)
