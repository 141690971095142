import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

import { ReduxState } from 'store'
import { ClockIcon, CrossIcon, EditIcon } from 'icons'
import { TravelType } from 'enums'
import { getTravelTypeInfo, hexColorToRGBA } from 'utils'
import { shadows } from '../../../constants'

const StyledTravelCard = styled.div<{color: string}>`
	font-size: 1rem;
    border: 3px solid ${(props) => hexColorToRGBA(props.color, .9)};
	
	background-color: ${(props) => hexColorToRGBA(props.color, .9)};
	color: #fff;
	${shadows.normal};
	
	@media (min-width: 900px) {
		border-radius: 9px;
		display: flex;
		flex-direction: row;
		margin-bottom: 17px;
		position: relative;
	}
	
	@media (max-width: 900px) {
		border-radius: 99px;
		height: 2.5rem;
		width: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: .5rem;
	}
`

const StyledTravelCardInfo = styled.div`
	@media (min-width: 900px) {
		flex: 1;
		padding: 0;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
	
	h1 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: 0;
		margin-right: 25px;
		padding: 4px 9px;
		font-size: 18px;
		color: #fff;
		font-family: HelveticaNeue, sans-serif !important;
		
		@media (max-width: 900px) {
			display: none;
		}
	}
`

const StyledTravelCardInfoMeta = styled.div`
	@media (min-width: 900px) {
		display: flex;
		flex-direction: row;
		padding: 5px 9px;
		background-color: #fff;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
`

const StyledTravelCardInfoMetaItem = styled.div`	
	@media (min-width: 900px) {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	&:not(:last-child) {
		margin-right: 2rem;
		
		@media (max-width: 900px) {
			display: none;
		}
	}


	p.label {
		font-size: 13px !important;
		font-family: HelveticaNeueMedium,sans-serif !important;
		font-weight: 600;
		color: rgba(0,0,0,0.8);
		text-transform: capitalize;
	}

	@media (max-width: 900px) {
		&:last-child {
			p {
				display: none;
			}
		}
	}
`

const StyledTravelCardInfoMetaItemIcon = styled.div<{color: string}>`
	display: flex;
	align-items: center;
	justify-content: center;
    border-radius: 99px;
	color: #fff;
	
	@media (min-width: 900px) {
		margin-right: .5rem;
		width: 21px;
		height: 21px;
		background: rgba(0,0,0,0.8);
		border: 2px solid rgba(0,0,0,0.8);

		svg {
			width: 13px;
			height: 13px;
		}
	}

	@media (max-width: 900px) {
		width: 34px;
		height: 34px;
		background: ${(props) => hexColorToRGBA(props.color, .9)};
		border: none;

		svg {
			width: 20px;
			height: 20px;
		}
	}
`

const StyledTravelCardAction = styled.div`
	padding: 0;
	position: absolute;
    right: 0;
	
	@media (max-width: 900px) {
		display: none;
	}
`

const StyledTravelCardActionItem = styled.div<{color: string}>`
	cursor: pointer;
	position: relative;
	width: 21px;
	height: 21px;
	border-radius: 50%;
	background-color: ${(props) => hexColorToRGBA(props.color, .9)};
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
    margin: 6px 5px;
	
	:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		//background-color: rgba(0, 0, 0, .1);
		border-radius: 50%;
		width: 100%;
		height: 100%;
		transition: 80ms;
	}
	:hover:before {
		transform: scale(1.25);
	}
	
	svg {
		width: 13px;
    	height: 13px;
	}
	
	&:not(:last-child) {
		margin-bottom: .9rem;
		background-color: transparent;

		svg {
			width: 19px;
    		height: 19px;
		}
	}
`

interface StateProps {}
interface DispatchProps {}
interface Props {
	color: string
	title: string
	duration: number
	transport: TravelType
	onDelete: () => any
	onEdit: () => any
}
type PropsUnion = StateProps & DispatchProps & Props & any

interface State {}

export class Component extends React.Component<PropsUnion, State> {
	public readonly state: State = {}

	public render() {
		const {color, title, duration, transport, onDelete, onEdit, t} = this.props
		return (
			<StyledTravelCard color={color}>
				<StyledTravelCardInfo>
					<h1>{title}</h1>
					<StyledTravelCardInfoMeta>
						<StyledTravelCardInfoMetaItem>
							<StyledTravelCardInfoMetaItemIcon color={color}>
								<ClockIcon/>
							</StyledTravelCardInfoMetaItemIcon>
							<p className='label'>{duration / 60} {t('minutes')}</p>
						</StyledTravelCardInfoMetaItem>
						<StyledTravelCardInfoMetaItem>
							<StyledTravelCardInfoMetaItemIcon color={color}>
								{getTravelTypeInfo(transport).icon}
							</StyledTravelCardInfoMetaItemIcon>
							<p className='label'>{t(getTravelTypeInfo(transport).name)}</p>
						</StyledTravelCardInfoMetaItem>
					</StyledTravelCardInfoMeta>
				</StyledTravelCardInfo>
				<StyledTravelCardAction>
					<StyledTravelCardActionItem onClick={onDelete} color={color}>
						<CrossIcon/>
					</StyledTravelCardActionItem>
					<StyledTravelCardActionItem onClick={onEdit} color={color}>
						<EditIcon/>
					</StyledTravelCardActionItem>
				</StyledTravelCardAction>
			</StyledTravelCard>
		)
	}
}

const mapStateToProps = (state: ReduxState) => ({})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

const TravelCardTranslation = withTranslation()(Component)

export const TravelCard = connect<StateProps, DispatchProps, Props, ReduxState>(
	mapStateToProps,
	mapDispatchToProps
)(TravelCardTranslation)
