import React from 'react'
import {withTranslation} from 'react-i18next'
import i18n from '../../../i18n/i18next'
import { getHistory } from '../../../utils/history'
import { inIframe } from "../../../utils/inIframe";
import {Menu, Row, Button} from 'antd'
import styled, { css } from 'styled-components'
import { MvaLogo } from 'icons'
import { InstructionSteps } from "../../app/instructionSteps";
import Modal from 'antd/lib/modal/Modal';
import ReactPlayer from 'react-player/lazy';

const StyledLogoContainer = styled.div`
	z-index: 1;
	position: fixed;
  right: 10px;
	top: 10px;
	
	svg {
		width: 175px;
		height: auto;
	}
`

const { SubMenu } = Menu;

class Component extends React.Component<any, any> {

  state = {
    modalActive: false,
    width: window.innerWidth
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({width: window.innerWidth})
    })
  }

  public render() {
    const user = this.getUser()

    const {t} = this.props
    return(
      <div>
        <StyledLogoContainer className="map-language">
          {
            inIframe() ?
            (null)
            :
            (<div className="language-buttons">
              <Button 
                className="white help-btn" 
                onClick={() => {
                  if(window.innerWidth > 900)
                    this.props.toggleInstruction(!this.props.showInstructions)
                  else
                    this.setState((prevState, props) => ({
                      modalActive: !prevState.modalActive
                    }))
                }}
              >
                <span >
                  {
                    this.props.showInstructions ? 
                    t('exitHelp')
                    :
                    t('help')
                  }
                </span>
              </Button>
              <Button className="white" onClick={() => this.changeLng('en')}><span >en</span></Button>
              <Button className="white" onClick={() => this.changeLng('nl- NL')}><span >nl</span></Button>
            </div>) 
          }
          <MvaLogo />
        </StyledLogoContainer>
        <Modal
					className="white-modal"
					visible={this.state.modalActive}
					onOk={() => this.setState({modalActive: false})}
					onCancel={() => this.setState({modalActive: false})}
					centered
					footer={null}
					title={null}
				>
					<ReactPlayer
						url={t('instructionModal.videoUrl')} 
						playing={this.state.modalActive}
					/>
				</Modal>
        <Menu mode="inline" className="user-menu">
          <Menu.SubMenu className="main-menu" title={<span>
            <span className="user-name">{user.name}</span>
            <span className="user-company">{user.company}</span>
            </span>}>
            <Menu.Item key="3">
              <span onClick={this.logout}>{t('Logout')}</span>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
        <div className="user-buttons login-buttons">
          <a href={t('MORE_INFO_URL')} target="_blank" >
            <Button className="green">{t('More information')}</Button>
          </a>
          <a href={t('FAQ_URL')} target="_blank" >
            <Button className="yellow">{t('F.A.Q.')}</Button>
          </a>
        </div>
        {this.props.showInstructions && this.state.width > 900 && (
          <InstructionSteps
            exitHelp={this.props.exitHelp}
            step={this.props.step}
            togglePlayer={this.props.togglePlayer}
          />
        )}
      </div>
    )
  }

  private getUser() {
    return JSON.parse(localStorage.getItem('user'))
  }

  private changeLng = (lng) => {
	  i18n.changeLanguage(lng)
  }

  private logout = () => {
    getHistory().push('/logout')
  }
}

const Profile = withTranslation()(Component)

export {Profile}