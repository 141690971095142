import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import styled, { css } from 'styled-components'
import Select from 'react-select'

import { ReduxState } from 'store'
import { TravelTimeAbstraction } from 'interfaces'
import { TravelType } from 'enums'
import { getTravelTypeInfo, hexColorToRGBA } from 'utils'
import { DropdownIcon, GoIcon, TrashcanIcon } from 'icons'
import { shadows } from '../../../constants'
import Spinner from "../../components/Spinner";

import { withTranslation } from 'react-i18next'
import { message } from 'antd'

// Component container styling
const StyledContainer = styled.div`	
	@media (min-width: 900px) {
		display: flex;
		flex-direction: row;
		background-color: #fff;
		border-radius: 9px;
		padding-left: 1rem;
		height: 60px;
		align-items: center;
		border: 3px solid rgba(23,163,69,1);
		${shadows.normal}
	}
	@media (min-width: 901px) and (max-width: 1050px) {
		margin-top: 50px;
	}
	@media (max-width: 900px) {
		display: flex;
		flex-direction: column;
	}
`

// Title wrapper
const StyledTitle = styled.div`
	p {
		margin: 0;
		font-family: HelveticaNeueBold, sans-serif !important;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 22px;
		color: rgba(0,0,0,0.7);
	}
	@media (min-width: 900px) {
		white-space: nowrap;
		display: flex;
		align-items: flex-end;
		height: 1.5rem;
		margin-bottom: 2px;
		margin-right: 1rem;
		p {
			font-size: 16px;
		}
	}
	@media (min-width: 901px) and (max-width: 1050px) {
		p {
			font-size: 22px;
			position: absolute;
			top: 20px;
			left: 20px;
		}
	}
    @media (max-width: 900px) {
    	text-align: center;
    	margin-bottom: 2rem;
		font-size: 1.25rem;
		p {
			font-size: 22px;
		}
    }
`

// Wrapper for the input fields
const StyledSegment = styled.div<{ withoutRightMargin?: boolean }>`
	display: flex;
	flex-direction: column;
	
	@media (min-width: 900px) {
		${(props) => !props.withoutRightMargin && css`
			margin-right: 1rem;
		`}
	}
	@media (max-width: 900px) {
    	padding: 0.6rem 1.5rem;
    	border-radius: 9px;
    	background: #fff;
		margin-bottom: .5rem;
		margin-right: 0 !important;
		border: 3px solid rgba(23,163,69,1);
    	height: 60px;
    }
`

// A colored label
const StyledLabel = styled.label<{ color: string }>`
	color: ${(props) => props.color};
`

/*
 * Styling wrapper for the `react-select` package, contains duplicate styling rules from:
 * 	- `StyledAddressInput`
 * 	- `StyledAutocompleteDropdownContainer`
 * 	- `StyledAutocompleteSuggestion`
 * 	merging these rules would mean a lot of overhead in parameter usage (minor differences)
 */
const StyledSelect = styled(Select) <{ color: string, minWidth: number }>`
	.react-select {
		position: relative;
		
		&__control {
			cursor: pointer;
			display: flex;
			flex-direction: row;
			background: #fff;
			min-width: ${(props) => props.minWidth}px;
			height: 1.5rem;
			
			@media (min-width: 900px) {
				border-bottom: 2px solid #d8d8d8;
				width: 170px;
				
				&--is-focused, &--menu-is-open {
					border-bottom: 3px solid #d8d8d8;
					/* // Fixes the height difference created by the focus effect -> prevents elements from jumping within the layout */
					margin-bottom: -1px;
				}
			}
		}
		&__value-container {
			padding-left: 0;
			padding-right: 0;
		}
		&__single-value {
			margin: 0;
			font-family: HelveticaNeue, sans-serif !important;
			font-weight: 700;
			text-transform: lowercase;
			color: rgba(0,0,0,0.4);
		}
		&__menu {
			position: absolute;
			top: 100%;
			min-width: 300px;
			margin-top: 1rem;
			${shadows.normal};
			border-radius: 9px;
			background-color: #fff;
			overflow: hidden;
			z-index: 10;
		}
		&__menu-list {
			padding: 0;
		}
		&__option {
			position: relative;
			cursor: pointer;
			transition: background-color 80ms;
			padding: 10px 5px;
			overflow: auto;
			
			:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 0;
				background-color: ${(props) => props.color}
			}
			&--is-focused, &--is-selected {					
				:before {
					width: 2px;
				}
			}
			
			&--is-focused {
				background: ${(props) => `linear-gradient(to right,
					${hexColorToRGBA(props.color, 0.25)},
					${hexColorToRGBA(props.color, 0.1)}
					)`};
			}
			
			&--is-selected {
				color: inherit;
				background: ${(props) => `linear-gradient(to right,
					${hexColorToRGBA(props.color, 0.4)},
					${hexColorToRGBA(props.color, 0.2)}
					)`};
			}
		}
	}
`

// Styling for the autocomplete container used with the `react-places-autocomplete` package
const StyledAutocompleteContainer = styled.div`
	position: relative;
`

// Styling for the input field used with the `react-places-autocomplete` package
const StyledAddressInput = styled.input`
	padding: 0;
	height: 1.5rem;
	outline: none;
	border: none;
	font-family: HelveticaNeue, sans-serif !important;
	font-weight: 700;

	::placeholder {
		color: rgba(0,0,0,0.4);
		text-transform: lowercase;
	}
	
	@media (min-width: 900px) {
		width: 200px;
		border-bottom: 2px solid #d8d8d8;
		
		:focus {
			border-bottom: 3px solid #d8d8d8;
			/* // Fixes the height difference created by the focus effect -> prevents elements from jumping within the layout */
			margin-bottom: -1px;
		}
	}
`

// Styling for the autocomplete dropdown container used with the `react-places-autocomplete` package
const StyledAutocompleteDropdownContainer = styled.div`
	position: absolute;
	top: 100%;
	min-width: 300px;
	margin-top: 1rem;
	${shadows.normal};
	border-radius: 9px;
	background-color: #fff;
	overflow: hidden;
	z-index: 10;
`

// Styling for the loader used with the `react-places-autocomplete` package
const StyledLoader = styled.div`
	padding: 10px;
`

// Styling for the autocomplete dropdown item used with the `react-places-autocomplete` package
const StyledAutocompleteSuggestion = styled.div<{ active: boolean, color: string }>`
	position: relative;
	cursor: pointer;
	transition: background-color 80ms;
	padding: 10px 5px;
	overflow: auto;
	
	:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 0;
		background-color: ${(props) => props.color}
	}
	${(props) => props.active && css`
		background: linear-gradient(to right,
			${hexColorToRGBA(props.color, 0.25)},
			${hexColorToRGBA(props.color, 0.1)}
			);
			
		:before {
			width: 2px;
		}
	`};
`

// Container for the actions
const StyledActionContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`

// Single action
const StyledActionButton = styled.div<{ color: string, isDisabled?: boolean, onlyVisibleOnMobile?: boolean }>`
	${(props) => props.onlyVisibleOnMobile && css`
		@media (min-width: 900px) {
			display: none;
		}
	`};
	cursor: ${(props) => props.isDisabled ? 'default' : 'pointer'};
	position: relative;
	background-color: ${(props) => props.color};
	opacity: ${(props) => props.isDisabled ? .5 : 1};
	border-radius: 99px;
	height: 44px;
	width: 44px;
	margin: 8px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 100ms;
	z-index: 0;
	
	${(props) => !props.isDisabled && css`
		:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 99px;
			background-color: ${hexColorToRGBA(props.color, .5)};
			transition: 100ms;
			z-index: -1;
		}
		:hover:before {
			transform: scale(1.2);
		}
	`};
`

// Container for the dropdown icon, used instead of the dropdown separator
const StyledDropdownIconContainer = styled.div`
	width: 1rem;
	height: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

const StyledCancelButtonContainer = styled.div`
	@media (min-width: 900px) {
		display: inline-block;
	}
	@media (max-width: 900px) {
		text-align: center;
	}
`

// Cancel button styles
const StyledCancelButton = styled.div`
	cursor: pointer;
	margin: 1rem 0;
	color: rgba(0,0,0,.45) !important;
	border-radius: 99px;
	padding: .3rem 1.8rem;
	background-color: #ffffff;
	border: 2px solid rgba(0,0,0,0.45);
	font-size: 20px !important;
	font-family: HelveticaNeueMedium,sans-serif !important;

	@media (max-width: 900px) {
		display: inline-block;
		text-align: center;
	}
`

interface StateProps { }
interface DispatchProps { }
interface Props extends Partial<TravelTimeAbstraction> {
	color: string
	onFinish: (v: TravelTimeAbstraction) => any
	onCancel: () => any
	onDelete?: (v: TravelTimeAbstraction) => any
}
type PropsUnion = StateProps & DispatchProps & Props & any

interface State extends TravelTimeAbstraction { firstTime: boolean}

// TODO Nikola fix tooltip for show houses and show travel time
// TODO Nikola remove select address when user selects it from map

export class Component extends React.Component<PropsUnion, State> {
	constructor(props: PropsUnion) {
		super(props)

		this.state = {
			title: props.title || '',
			location: props.location || { lat: -1, lng: -1 },
			duration: props.duration || 900,
			transport: props.transport || TravelType.Walking,
			firstTime: true
		}
	}

	// componentWillReceiveProps(){
	// 	this.setState({firstTime: false})
	// }

	public render() {
		const {t} = this.props
		// TODO Nikola fix this edit address showing first time when there is address selected
		// if (this.state.firstTime && this.props.travelTimes !== null) return null
		return (
			<>
				<StyledContainer>
					<StyledTitle>
						<p>{t('How far would I live from')}:</p>
					</StyledTitle>
					{this.renderInputAddress()}
					{this.renderInputTraveltype()}
					{this.renderInputDuration()}
					<StyledActionContainer>
						<StyledActionButton
							color={this.props.color}
							onClick={() => {
								if(!this.isAddressSelected()) {
									message.info(t('Input address first'))
									return
								}
								this.props.onFinish(this.state)
								this.props.setStep('2')
							}}
						>
							<GoIcon />
						</StyledActionButton>
						{this.props.onDelete && <StyledActionButton
							color={this.props.color}
							onClick={() => this.props.onDelete!(this.state)}
							onlyVisibleOnMobile={true}
						>
							<TrashcanIcon />
						</StyledActionButton>}
					</StyledActionContainer>
				</StyledContainer>
				<StyledCancelButtonContainer>
					<StyledCancelButton className="label" onClick={() => this.props.onCancel()}>{t('cancel')}</StyledCancelButton>
				</StyledCancelButtonContainer>
				<div className="message-click">
					<span className="message-white">{t('Or click somewhere on the map')}</span>
				</div>
			</>
		)
	}

	private isAddressSelected() {
		const {duration,location,title,transport} = this.state
		return title != '' && title && duration && location && transport
	}

	private renderInputAddress() {
		const { t } = this.props
		return (
			<PlacesAutocomplete
				value={this.state.title}
				onChange={this.handlePlacesAutocompleteChange}
				onSelect={this.handlePlacesAutocompleteSelect}
				searchOptions={{
					location: new google.maps.LatLng(52.3645568, 4.8958031),
					radius: 25000,
					types: ['address']
				}}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
					<StyledAutocompleteContainer>
						<StyledSegment>
							<StyledLabel htmlFor='address' className='label' color={this.props.color}>{t('From')}</StyledLabel>
							<StyledAddressInput
								{...getInputProps({
									placeholder: 'Address',
									name: 'address',
									onBlur: () => window.scrollTo(0, 0)
								})}
							/>
						</StyledSegment>
						<StyledAutocompleteDropdownContainer>
							{/* {loading && <Spinner />} */}
							{suggestions.map(suggestion => {
								return (
									<StyledAutocompleteSuggestion
										key={suggestion.id}
										{...getSuggestionItemProps(suggestion)}
										active={suggestion.active}
										color={this.props.color}
									>
										<p>{suggestion.description}</p>
									</StyledAutocompleteSuggestion>
								);
							})}
						</StyledAutocompleteDropdownContainer>
					</StyledAutocompleteContainer>
				)}
			</PlacesAutocomplete>
		)
	}

	private handlePlacesAutocompleteChange = (title: string) => {
		this.setState({ title })
	}

	private handlePlacesAutocompleteSelect = (title: string) => {
		geocodeByAddress(title)
			.then(async (results) => {
				this.setState({
					title,
					location: await getLatLng(results[0])
				})
			})
	}

	private renderInputDuration() {
		type Entry = { value: number, label: string }
		let options: Entry[]
		const {t} = this.props
		if (this.state.transport === 'driving') {
			options = [
				{ value: 900, label: '15 ' + t('minutes') },
				{ value: 1800, label: '30 ' + t('minutes') },
				{ value: 2700, label: '45 ' + t('minutes') }
			]
			if (this.state.duration === 3600) {
				this.setState({ duration: 2700})
			}
		} else {
			options = [
				{ value: 900, label: '15 ' + t('minutes') },
				{ value: 1800, label: '30 ' + t('minutes') },
				{ value: 2700, label: '45 ' + t('minutes') },
				{ value: 3600, label: '60 ' + t('minutes') },
			]
		}
		return (
			<StyledSegment>
				<StyledLabel className='label' as='p' color={this.props.color}>{t('in')}</StyledLabel>
				<StyledSelect
					styles={{ control: () => null }}
					classNamePrefix='react-select'
					minWidth={110}
					value={options.filter((t) => t.value === this.state.duration)[0] || null}
					onChange={(v: Entry) => {v && 'value' in v && this.setState({ duration: v.value }); if(v.value===2700) message.info(t('This operation can take some time')); }}
					onBlur={() => window.scrollTo(0, 0)}
					options={options}
					components={{
						DropdownIndicator: () => <DropdownIcon style={{ marginLeft: '.25rem' }} />,
						IndicatorSeparator: null
					}}
					color={this.props.color}
				/>
			</StyledSegment>
		)
	}

	private renderInputTraveltype() {
		const {t} = this.props
		type Entry = { value: TravelType, label: React.ReactNode }
		const options: Entry[] = [
			{ value: TravelType.Walking, label: t(getTravelTypeInfo(TravelType.Walking).name) },
			{ value: TravelType.PublicTransportAndCycling, label: t(getTravelTypeInfo(TravelType.PublicTransportAndCycling).name) },
			{ value: TravelType.PublicTransport, label: t(getTravelTypeInfo(TravelType.PublicTransport).name) },
			{ value: TravelType.Cycling, label: t(getTravelTypeInfo(TravelType.Cycling).name) },
			{ value: TravelType.Driving, label: t(getTravelTypeInfo(TravelType.Driving).name) }
		]

		const currentOption = options.filter((t) => t.value === this.state.transport)[0]

		return (
			<StyledSegment withoutRightMargin={true} style={{marginRight: '16px'}}>
				<StyledLabel className='label' as='p' color={this.props.color}>{t('by')}</StyledLabel>
				<StyledSelect
					styles={{ control: () => null }}
					classNamePrefix='react-select'
					minWidth={160}
					value={currentOption}
					onChange={(v: Entry) => v && 'value' in v && this.setState({ transport: v.value })}
					onBlur={() => window.scrollTo(0, 0)}
					options={options}
					components={{
						DropdownIndicator: () => <DropdownIcon style={{ marginLeft: 10 }} />,
						IndicatorSeparator: () => (
							<StyledDropdownIconContainer>
								{getTravelTypeInfo(currentOption.value).icon}
							</StyledDropdownIconContainer>
						)
					}}
					color={this.props.color}
				/>
			</StyledSegment>
		)
	}

	private isValid(travelTime: TravelTimeAbstraction): boolean {
		return (
			!!travelTime.title
			&& travelTime.location.lat > 0
			&& travelTime.location.lng > 0
			&& !!travelTime.transport
			&& travelTime.duration > 0
		)
	}
}

const mapStateToProps = (state: ReduxState) => ({
	travelTimes: state.travelTime.travelTimes
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

const EditTravelTim = withTranslation()(Component)

export const EditTravelTime = connect<StateProps, DispatchProps, Props, ReduxState>(
	mapStateToProps,
	mapDispatchToProps
)(EditTravelTim)
