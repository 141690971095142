import React from 'react'
import { Row, Col, Card, Alert, Form, Button, Input, InputNumber, Select } from 'antd'
import styled from 'styled-components'
import { getHistory } from '../../../utils/history'
import { getCode } from '../../../utils/promoCode'
import { withTranslation } from 'react-i18next'
import { IDENTITY_CONFIG } from "../../OidcComponent/oidcsettings";
// tslint:disable-next-line: no-submodule-imports
import 'antd/lib/message/style'
import { LogoIcon, MvaIcon, ClearstoneIcon, MvaLogo, IdealIcon } from 'icons'
import i18n from "../../../i18n/i18next";
import { inIframe } from "../../../utils/inIframe";
import { CloseCircleOutlined, PhoneOutlined, MailOutlined, CheckOutlined } from '@ant-design/icons';
import { withCookies } from 'react-cookie';
import { getAddress, getBanks, getUserAddress, postOrder } from 'utils/buyNowCalls'
import './style.css'
import Spinner from '../../components/Spinner'

const { Option } = Select;

const CenteredDiv = styled.div`
	position: absolute;
`

const MarginSpan = styled.span`
	margin: 0px 10px
`

const StyledLogoContainer = styled.div`
	z-index: 10;
`
const StyledLogoContainerIndividual = styled.div`	
	z-index: 10;
	margin-left:10rem;
	padding-left:10rem;
`

const StyledCookie = styled.div`
	-webkit-box-shadow: 0 0 25px rgba(0,0,0,0.2);
	-moz-box-shadow: 0 0 25px rgba(0,0,0,0.2);
	-ms-box-shadow: 0 0 25px rgba(0,0,0,0.2);
	-o-box-shadow: 0 0 25px rgba(0,0,0,0.2);
	box-shadow: 0 0 25px rgba(0,0,0,0.2);
	font-weight: 700;
	text-align: left;
	z-index: 9999;
	color: #000;
	background: rgb(255, 255, 255, 0.9);
	width: 100%;
    min-height: 30px;
    padding: 10px 0;
    position: fixed;
	bottom: 0;
	
	p {
		padding-left: 50px;
		padding-right: 50px;
		font-weight: 700 !important;
		font-size: 15px;
		line-height: 21px;

		span {
			font-weight: 400;

			a {
				color: #000;
				text-decoration: underline;
			}
		}
	}

	button {
		color: #000 !important;
		font-size: 30px !important;
		width: 30px;
		height: 30px !important;
		position: absolute;
		top: 17px;
		right: 20px;
		padding: 0 !important;
		background-color: transparent !important;
	}
	
	@media (min-width: 900px) {

	}
	
	@media (max-width: 900px) {
		
	}
`

interface State {
	postalCode: string;
	houseNumber: any;
	houseNumberAddition: string;
	cookie: boolean;
	streetName: string;
	city: string;
	bank: string;
	banks: any;
	proccessingBuyNow: boolean;
}

class Component extends React.Component<any, State> {
	constructor(props) {
		super(props)
		this.state = {
			postalCode: '',
			houseNumber: '',
			houseNumberAddition: null,
			cookie: this.props.cookies.get('cookie') === undefined ? true : false,
			streetName: null,
			city: null,
			bank: null,
			banks: null,
			proccessingBuyNow: false
		}
	}

	public render() {
		const { t } = this.props
		const { banks } = this.state
		const self = this

		if (this.state.proccessingBuyNow) return <Spinner />

		return (
			<CenteredDiv className="home container-fluid center">
				<section className="slider slider-blue container-fluid">
					{
						inIframe() ?
							(null)
							:
							(<div className="language-buttons">
								<Button className="white" onClick={() => this.changeLng('en')}><span >en</span></Button>
								<Button className="white" onClick={() => this.changeLng('nl-NL')}><span >nl</span></Button>
							</div>)
					}
					<div className="container">
						<Row gutter={[16, 16]} align="top">
							<div className="slider-circle-content hidden-xs"></div>
							<div className="slider-brand">
								<div className="container">
									<div className="row">
										<div className="col-xs-12 text-left">
											<a href="/">
												<MvaLogo />
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="slider-headline">
								<div className="container">
									<div className="row hidden-xs">
										<div className="col-xs-12">
											<h1 dangerouslySetInnerHTML={
												{ __html: t('SLIDER_HEADLINE', { interpolation: { escapeValue: false } }) }
											}
											>
											</h1>
										</div>
									</div>
								</div>
							</div>
						</Row>
					</div>
				</section>
				<section className="register container center" id="loginId">
					<Row gutter={[16, 16]} justify="center" align="top">
						<Col xs={24} lg={24} className="agent text-left">
							<p>{t('buyCode.mainText')}</p>
							<Alert
								className="text-left"
								style={{ margin: '25px 0 17px' }}
								message={t('buyCode.alertMessage')}
								description={t('buyCode.alertMessageDescription')}
								type="error"
								showIcon
							/>
						</Col>
						<Col xs={24} lg={24} className="text-left">
							<Row gutter={[16, 16]} className="card" justify="center" align="top">
								<Col xs={24} lg={12} className="price-plan text-left">
									<div className="text-center price">
										<p className="currency">€</p>
										<p className="value">4</p>
										<p className="decimal">99</p>
										<p className="period">per month</p>
									</div>
									<div className="text-center features">
										<ul>
											<li>
												<CheckOutlined /><span className="">{t('buyCode.feature1')}</span>
											</li>
											<li>
												<CheckOutlined /><span className="">{t('buyCode.feature2')}</span>
											</li>
											<li>
												<CheckOutlined /><span className="">{t('buyCode.feature3')}</span>
											</li>
											<li>
												<CheckOutlined /><span className="">{t('buyCode.feature4')}</span>
											</li>
										</ul>
									</div>
									<div className="text-center ideal">
										<IdealIcon style={{ width: '35px' }} />
										<span>{t('buyCode.idealNotice')}</span>
									</div>
								</Col>
								<Col xs={24} lg={12} className="agent text-left">
									<div className="text-left">
										<p>{t('buyCode.formText')}</p>
										<div className="email-form">
											<Form
												labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
												wrapperCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
												layout="vertical"
												size='middle'
											>
												<Input.Group className='address-group'>
													<Row gutter={8}>
														<Col className='px-0' xs={24} sm={24} md={8} style={{ paddingLeft: '0' }}>
															<Form.Item
																label={t('buyCode.postalCodeLabel')}
																name="PostalCode"
																rules={[
																	({ getFieldValue }) => ({
																		validator(_, value) {
																			if (self.state.postalCode) {
																				return Promise.resolve()
																			}
																			return Promise.reject(new Error(t('fieldRequired')));
																		},
																	}),
																]}
																required
															>
																<Input
																	type="text"
																	onChange={(e) => {
																		this.setState({ postalCode: e.target.value })
																	}}
																	onBlur={() => this.addressChanged()}
																	value={this.state.postalCode}
																/>
																<p hidden>{this.state.postalCode}</p>
															</Form.Item>
														</Col>
														<Col className='px-0' xs={24} sm={24} md={8}>
															<Form.Item
																label={t('buyCode.houseNumberLabel')}
																name="HouseNumber"
																rules={[
																	({ getFieldValue }) => ({
																		validator(_, value) {
																			if (self.state.houseNumber) {
																				return Promise.resolve()
																			}
																			return Promise.reject(new Error(t('fieldRequired')));
																		},
																	}),
																]}
																required
															>
																<Input
																	style={{ width: '100%' }}
																	onBlur={() => this.addressChanged()}
																	onChange={(e) => {
																		this.setState({ houseNumber: e.target.value })
																	}}
																	value={this.state.houseNumber}
																	type="number"
																/>
																<p hidden>{this.state.houseNumber}</p>
															</Form.Item>
														</Col>
														<Col className='px-0' xs={24} sm={24} md={8} style={{ paddingRight: '0' }}>
															<Form.Item
																label={t('buyCode.additionLabel')}
																name="Addition"
															>
																<Input
																	type="text"
																	onChange={(e) => {
																		this.setState({ houseNumberAddition: e.target.value })
																	}}
																	onBlur={() => this.addressChanged()}
																	value={this.state.houseNumberAddition}
																/>
																<p hidden>{this.state.houseNumberAddition}</p>
															</Form.Item>
														</Col>
													</Row>
												</Input.Group>
												<Input.Group className='address-group'>
													<Row gutter={8}>
														<Col className='px-0' xs={24} sm={24} md={12} style={{ paddingLeft: '0' }}>
															<Form.Item
																label={t('buyCode.streetLabel')}
																name="Street"
																rules={[
																	({ getFieldValue }) => ({
																		validator(_, value) {
																			if (self.state.streetName) {
																				return Promise.resolve()
																			}
																			return Promise.reject(new Error(t('fieldRequired')));
																		},
																	}),
																]}
																required
															>
																<Input
																	type="text"
																	onChange={(e) => {
																		this.setState({ streetName: e.target.value })
																	}}
																	value={this.state.streetName}
																/>
																{/* really stupid workaround, but without this,
																 when state is changed it will not render on input filed 
																*/}
																<p hidden>{this.state.streetName}</p>
															</Form.Item>
														</Col>
														<Col className='px-0' xs={24} sm={24} md={12} style={{ paddingRight: '0' }}>
															<Form.Item
																label={t('buyCode.cityLabel')}
																name="City"
																rules={[
																	({ getFieldValue }) => ({
																		validator(_, value) {
																			if (self.state.city) {
																				return Promise.resolve()
																			}
																			return Promise.reject(new Error(t('fieldRequired')));
																		},
																	}),
																]}
																required
															>
																<Input
																	type="text"
																	onChange={(e) => {
																		this.setState({ city: e.target.value })
																	}}
																	value={this.state.city}
																/>
																{/* really stupid workaround, but without this,
																 when state is changed it will not render on input filed 
																*/}
																<p hidden>{this.state.city}</p>
															</Form.Item>
														</Col>
													</Row>
												</Input.Group>
												<Form.Item
													label={t('buyCode.idealLabel')}
													name="iDEAL"
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																if (value) {
																	self.setState({bank: value})
																	return Promise.resolve()
																}
																return Promise.reject(new Error(t('fieldRequired')));
															},
														}),
													]}
													required
												>
													<Select
														defaultValue={t('buyCode.idealBank')}
														value={this.state.bank}
													>
														<option value={null}>{t('buyCode.idealBank')}</option>
														{
															banks && banks.map(r => (
																<option value={r.bicCode}>{r.name}</option>
															))
														}
													</Select>
													</Form.Item>
												<Form.Item className="register-buttons btn-submit">
													<Button
														block
														type="primary"
														className="green"
														style={{ marginTop: '10px', lineHeight: '30px', padding: '20px 0', textTransform: 'uppercase', fontSize: '30px' }}
														onClick={() => this.sendBuyNowRequest()}
														htmlType="submit"
													>
														{t('buyCode.submit')}
													</Button>
												</Form.Item>
											</Form>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</section>
				<section className="services container-fluid center">
					<div className="container">
						<div className="row">
							<Row gutter={[16, 16]} justify="center" align="middle">
								<Col xs={24} md={8} className="text-center">
									<div className="service service-1">
										<figure className="effect-ming">
											<figcaption>
												<div className="icons">
													<div className="ico service-icon">
														<img data-src="/pages/img/icons/service-valuation.png" className="img-responsive lazy loaded" alt="Current market value" src="/pages/img/icons/service-valuation.png" data-was-processed="true" />
													</div>
												</div>
												<h3>{t('Current market value')}</h3>
												<p>{t('MarketValueText')}</p>
											</figcaption>
										</figure>
									</div>
								</Col>
								<Col xs={24} md={8} className="text-center">
									<div className="service service-2">
										<figure className="effect-ming">
											<figcaption>
												<div className="icons">
													<div className="ico service-icon">
														<img data-src="/pages/img/icons/service-travel.png" className="img-responsive lazy loaded" alt="Travel time" src="/pages/img/icons/service-travel.png" data-was-processed="true" />
													</div>
												</div>
												<h3>{t('Travel time')}</h3>
												<p>{t('TravelTimeText')}</p>
											</figcaption>
										</figure>
									</div>
								</Col>
								<Col xs={24} md={8} className="text-center">
									<div className="service service-3">
										<figure className="effect-ming">
											<figcaption>
												<div className="icons">
													<div className="ico service-icon">
														<img data-src="/pages/img/icons/service-sell.png" className="img-responsive lazy loaded" alt="Offer" src="/pages/img/icons/service-sell.png" data-was-processed="true" />
													</div>
												</div>
												<h3>{t('Offer')}</h3>
												<p>{t('OfferText')}</p>
											</figcaption>
										</figure>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</section>
				<section className="info container-fluid center">
					<div className="container">
						<Row gutter={[16, 16]} justify="center" align="top">
							<Col xs={24} lg={16} xl={18} className="text-left">
								<div className="info-txt">
									<h2 className="yellow">{t('INFO_INTRO')}</h2>
								</div>
							</Col>
							<Col xs={24} lg={8} xl={6} className="text-right info-buttons">
								<Row gutter={[16, 16]} align="top">
									<Col xs={24} sm={12} lg={24} className="text-right">
										<a href={t('MORE_INFO_URL')}>
											<Button className="green">{t('More information')}</Button>
										</a>
									</Col>
									<Col xs={24} sm={12} lg={24} className="text-right">
										<a href={t('FAQ_URL')}>
											<Button className="yellow">{t('Frequently asked questions')}</Button>
										</a>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</section>
				<footer>
					<div className="copyright-footer">
						<div className="container">
							<Row gutter={[16, 16]} justify="center" align="top">
								<Col xs={24} className="text-left">
									<div className="login-logos">
										<StyledLogoContainer>
											<a href="https://www.mva.nl/" className="svg" target="_blank" rel="noopener noreferrer"><MvaIcon /></a>
											<a href="https://www.momentum-technologies.nl/" className="svg" target="_blank" rel="noopener noreferrer"><LogoIcon /></a>
											<a href="https://www.clearstone.nl/" className="svg" target="_blank" rel="noopener noreferrer"><ClearstoneIcon /></a>
											<span
												dangerouslySetInnerHTML={
													{ __html: t('Based on the open-source MapitOut application', { interpolation: { escapeValue: false } }) }
												}
											>
											</span>
										</StyledLogoContainer>
									</div>
								</Col>
								<Col xs={24} md={19} className="text-left copyright">
									<p
										dangerouslySetInnerHTML={
											{ __html: t('copyrightBar', { interpolation: { escapeValue: false } }) }
										}
									>
									</p>
								</Col>
								<Col xs={24} md={5} className="text-right contact-icons">
									{/* <a href='tel:+31153801685'><PhoneOutlined /></a> */}
									<a href='mailto:homelocator@momentum-technologies.nl'><MailOutlined /></a>
								</Col>
							</Row>
						</div>
					</div>
				</footer>
				<div className="cookie-bar">
					<Row>
						{this.state.cookie ?
							(<StyledCookie>
								<div>
									<p
										dangerouslySetInnerHTML={
											{ __html: t('cookieBar', { interpolation: { escapeValue: false } }) }}
									></p>
									<Button
										icon={<CloseCircleOutlined />}
										onClick={() => { this.props.cookies.set('cookie', false, { path: '/' }); this.setState({ cookie: false }); }}
									>
									</Button>
								</div>
							</StyledCookie>)
							:
							null}
					</Row>
				</div>
			</CenteredDiv>
		)
	}

	public componentDidMount() {
		document.body.style.overflow = 'auto'
		localStorage.setItem('lastVisited', '/buy-now')
		if (!this.isAuthenticated()) {
			getHistory().push('/app')
		}
		getBanks().then(r => {
			this.setState({ banks: r })
		})
		getUserAddress().then(r => {
			if (!r) return
			this.setState({
				postalCode: r.postalCode,
				houseNumber: r.number,
				houseNumberAddition: r.numberAddition,
				streetName: r.street,
				city: r.city
			})
		})
	}

	sendBuyNowRequest() {
		const { streetName, houseNumber, houseNumberAddition, postalCode, city, bank } = this.state
		if (!postalCode || !houseNumber || !streetName || !city || !bank) return
		const data = {
			address: {
				street: streetName,
				number: parseInt(houseNumber),
				numberAddition: houseNumberAddition,
				postalCode: postalCode,
				city: city,
				countryCode: 'NL'
			},
			Locale: i18n.language === 'en' ? 'en-GB' : i18n.language === 'nl-NL' ? 'nl-NL' : '',
			bicCode: bank
		}
		this.setState({ proccessingBuyNow: true })
		postOrder(data, this.props.t).then(r => {
			this.setState({ proccessingBuyNow: false })
		}).catch(e => {
			this.setState({ proccessingBuyNow: false })
		})
	}

	addressChanged() {
		const { postalCode, houseNumber, houseNumberAddition } = this.state
		if (postalCode && houseNumber) {
			getAddress(postalCode, houseNumber, houseNumberAddition).then(r => {
				this.setState({
					streetName: r.street,
					city: r.city
				})
			})
		}
	}

	private userLogin() {
		// tslint:disable-next-line: no-console
		getHistory().push('/app')
	}

	private changeLng = (lng) => {
		i18n.changeLanguage(lng)
	}

	private isAuthenticated = () => {
		const oidcStorage = JSON.parse(
			sessionStorage.getItem(
				`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`,
			),
		)

		return !!oidcStorage && !!oidcStorage.access_token
	}
}

const IndexCookies = withCookies(Component) as any

const BuyPage = withTranslation()(IndexCookies)

export { BuyPage }
