import React from "react";

import { OidcConsumer } from "./index";
import Spinner from "../components/Spinner"

export const Callback = (props) => (
  <OidcConsumer>
    {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return <Spinner />;
        }}
  </OidcConsumer>
)
