import { message } from 'antd'
import axios from 'axios'
import { getToken } from './oAuth'

export const getAddress = async (postalCode, number, numberAddition) => {
	const url = `${process.env.REACT_APP_API_BASE_URL}/addresses/${postalCode}/${number}/${numberAddition ? numberAddition : '.*'}`
	const response = await axios.get(url).then(r => {
		return r.data.data
	}).catch(e => {
		return e.response.status
	})

	return response
}

export const postOrder = async (data, t) => {
	const url = `${process.env.REACT_APP_API_BASE_URL}/orders`
	const response = await axios.post(url, data, { headers: { Authorization: `Bearer ${getToken()}` } }).then(r => {
		if (r.status === 201) {
			window.location.href = r.headers.location
		}
	}).catch(e => {
		message.warn(t('Please try again'))
	})

	return response
}

export const getBanks = async () => {
	const url = `${process.env.REACT_APP_API_BASE_URL}/issuers`
	const response = await axios.get(url,  { headers: { Authorization: `Bearer ${getToken()}` } }).then(r => {
		return r.data.data
	}).catch(e => {
		// TODO FIX return e.response.status
		return e
	})

	return response
}

export const getUserAddress = async () => {
	const url = `${process.env.REACT_APP_API_BASE_URL}/users/address`
	const response = await axios.get(url,  { headers: { Authorization: `Bearer ${getToken()}` } }).then(r => {
		return r.data.data
	}).catch(e => {
		return null
	})

	return response
}