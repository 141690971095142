import React from "react";

import { OidcConsumer } from "./index";
import Spinner from "../components/Spinner"

export const Logout = (props) => (
  <OidcConsumer>
    {({ logout }) => {
            logout();
            return <Spinner />;
        }}
  </OidcConsumer>
);
