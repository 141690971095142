import { ActionDispatch } from './reducer'
import { Tooltip } from './lib/Tooltip'

export enum ActionType {
	SetZoomLevel = '@@application/zoom',
	SetOverlapState = '@@application/overlap',
	SetTooltip = '@@application/tooltip',
	SetPrimaryEducationVisibility = '@@application/primary-education',
	SetSecondaryEducationVisibility = '@@application/secondary-education',
	SetMteOverlayVisibility = '@@application/mte-overlay',
	SetMteCardVisibility = '@@application/mte-card',
	SetGeoJsonData = '@@application/set-geojson',
	SetGeoJsonDataMarkers = '@@application/set-geojson-markers',
	SetLegend = '@@application/set-legend',
	SetLastCheckedInfoWindowId = '@@application/set-info-window-id',
	SetBounds = '@@application/set-bounds'
}

export function setBounds(data: any) {
	return (dispatch: (action: ActionDispatch) => {}) => {
		dispatch({
			type: ActionType.SetBounds,
			data: data
		})
	}
}

export function setLastCheckedInfoWindowId(data: any) {
	return (dispatch: (action: ActionDispatch) => {}) => {
		dispatch({
			type: ActionType.SetLastCheckedInfoWindowId,
			data: data
		})
	}
}

export function setLegend(data: any) {
	return (dispatch: (action: ActionDispatch) => {}) => {
		dispatch({
			type: ActionType.SetLegend,
			data: data
		})
	}
}

export function setGeoJsonDataMarkers(data: any) {
	return (dispatch: (action: ActionDispatch) => {}) => {
		dispatch({
			type: ActionType.SetGeoJsonDataMarkers,
			data: data
		})
	}
}

export function setGeoJsonData(data: any) {
	return (dispatch: (action: ActionDispatch) => {}) => {
		dispatch({
			type: ActionType.SetGeoJsonData,
			data: data
		})
	}
}

export function setZoomLevel(level: number) {
	return (dispatch: (action: ActionDispatch) => {}) => {
		dispatch({
			type: ActionType.SetZoomLevel,
			data: level
		})
	}
}

export function setOverlapState(displayEnabled: boolean) {
	return (dispatch: (action: ActionDispatch) => {}) => {
		dispatch({
			type: ActionType.SetOverlapState,
			data: displayEnabled
		})
	}
}

export function setTooltip(tooltip: Tooltip | null) {
	return (dispatch: (action: ActionDispatch) => {}) => {
		dispatch({
			type: ActionType.SetTooltip,
			data: tooltip
		})
	}
}

export function setPrimaryEducationVisibility(visible: boolean) {
	return (dispatch: (action: ActionDispatch) => {}) => {
		dispatch({
			type: ActionType.SetPrimaryEducationVisibility,
			data: visible
		})
	}
}

export function setSecondaryEducationVisibility(visible: boolean) {
	return (dispatch: (action: ActionDispatch) => {}) => {
		dispatch({
			type: ActionType.SetSecondaryEducationVisibility,
			data: visible
		})
	}
}

export function setMteOverlay(visible: boolean) {
	return (dispatch: (action: ActionDispatch) => {}) => {
		dispatch({
			type: ActionType.SetMteOverlayVisibility,
			data: visible
		})
	}
}

export function setMteCard(value: string | null) {
	return (dispatch: (action: ActionDispatch) => {}) => {
		dispatch({
			type: ActionType.SetMteCardVisibility,
			data: value
		})
	}
}

