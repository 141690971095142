import React from "react";
import { Route } from "react-router-dom";

import { OidcConsumer } from "./index";
import Spinner from "../components/Spinner"

export const PrivateRoute = ({ component, ...rest }) => {
  const renderFn = (Component) => (props) => {
    return (
      <OidcConsumer>
        {({ isAuthenticated, signinRedirect }) => {
          if (isAuthenticated()) {
            return <Component {...props} />;
          }
          signinRedirect();
          return <Spinner />;

        }}
      </OidcConsumer>
    );
  }

  return <Route {...rest} render={renderFn(component)} />;
};
