import React from 'react'
import {Row, Col, Card, Button, Input, message, Modal, Menu} from 'antd'
import styled from 'styled-components'
import {getHistory} from '../../../utils/history'
import {getCode} from '../../../utils/promoCode'
import {withTranslation} from 'react-i18next'
import { IDENTITY_CONFIG } from "../../OidcComponent/oidcsettings";
// tslint:disable-next-line: no-submodule-imports
import 'antd/lib/message/style'
import {LogoIcon, MvaIcon, ClearstoneIcon, TravelTimeIcon, MetropoolIcon, MvaLogo} from 'icons'
import i18n from "../../../i18n/i18next";
import { inIframe } from "../../../utils/inIframe";
import { CloseCircleOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player/lazy'
import { withCookies, Cookies } from 'react-cookie';

const CenteredDiv = styled.div`
	position: absolute;
`

const MarginSpan = styled.span`
	margin: 0px 10px
`

const StyledLogoContainer = styled.div`
	z-index: 10;
`
const StyledLogoContainerIndividual = styled.div`	
	z-index: 10;
	margin-left:10rem;
	padding-left:10rem;
`

const StyledCookie = styled.div`
	-webkit-box-shadow: 0 0 25px rgba(0,0,0,0.2);
	-moz-box-shadow: 0 0 25px rgba(0,0,0,0.2);
	-ms-box-shadow: 0 0 25px rgba(0,0,0,0.2);
	-o-box-shadow: 0 0 25px rgba(0,0,0,0.2);
	box-shadow: 0 0 25px rgba(0,0,0,0.2);
	font-weight: 700;
	text-align: left;
	z-index: 9999;
	color: #000;
	background: rgb(255, 255, 255, 0.9);
	width: 100%;
    min-height: 30px;
    padding: 10px 0;
    position: fixed;
	bottom: 0;
	
	p {
		padding-left: 50px;
		padding-right: 50px;
		font-weight: 700 !important;
		font-size: 15px;
		line-height: 21px;

		span {
			font-weight: 400;

			a {
				color: #000;
				text-decoration: underline;
			}
		}
	}

	button {
		color: #000 !important;
		font-size: 30px !important;
		width: 30px;
		height: 30px !important;
		position: absolute;
		top: 17px;
		right: 20px;
		padding: 0 !important;
		background-color: transparent !important;
	}
	
	@media (min-width: 900px) {

	}
	
	@media (max-width: 900px) {
		
	}
`

type CodeName = 'code1' | 'code2' | 'code3'

interface State {
	code1: string;
	code2: string;
	code3: string;
	cookie: boolean;
	modal1Visible: boolean;
	modal2Visible: boolean;
	playerActive: boolean;
}

class Component extends React.Component<any, State> {

	private capitalLettersOnlyRegex = /^[A-Z]*$/g
	private scratchCode1
	private scratchCode2
	private scratchCode3

	componentWillUnmount(){document.body.style.overflow='hidden'}

	constructor(props) {
		super(props)
		this.state = {
			code1: '',
			code2: '',
			code3: '',
			cookie: this.props.cookies.get('cookie') === undefined ? true : false,
			modal1Visible: false,
			modal2Visible: false,
			playerActive: false,
		}
		this.codeCopied = this.codeCopied.bind(this)
	}

	setModal1Visible(modal1Visible) {
		this.setState({ modal1Visible });
	}

	setModal2Visible(modal2Visible) {
		this.setState({ modal2Visible });
	}

	public togglePlayer = (active) => {
		this.setState({
			playerActive: active
		})
	}

	public render() {
		const {t} = this.props

		this.handleNextFocus()

		return (
			<CenteredDiv className="home container-fluid center">
				<section className="slider slider-blue container-fluid">
					{
						inIframe() ?
						(null)
						:
						(<div className="language-buttons">
							<a href="#loginId" className="white"><span>{t('Login')}</span></a>
							<Button className="white" onClick={() => this.changeLng('en')}><span >en</span></Button>
							<Button className="white" onClick={() => this.changeLng('nl-NL')}><span >nl</span></Button>
						</div>) 
					}
					<div className="container">
						<Row gutter={[16, 16]} align="top">
							<div className="slider-circle-content hidden-xs"></div>
							<div className="slider-brand">
								<div className="container">
									<div className="row">
										<div className="col-xs-12 text-left">
											<a href="/">
												<MvaLogo/>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="slider-headline">
								<div className="container">
									<div className="row hidden-xs">
										<div className="col-xs-12">
											<h1 dangerouslySetInnerHTML={
												{__html: t('SLIDER_HEADLINE', {interpolation: {escapeValue: false}})}
											}
											>
											</h1>
										</div>
									</div>
								</div>
							</div>
						</Row>
					</div>
				</section>
				<section className="services container-fluid center">
					<div className="container">
						<div className="row">
							<Row gutter={[16, 16]} justify="center" align="middle">
								<Col xs={24} md={8} className="text-center">
									<div className="service service-1">
										<figure className="effect-ming">
											<figcaption>
												<div className="icons">
													<div className="ico service-icon">
														<img data-src="/pages/img/icons/service-valuation.png" className="img-responsive lazy loaded" alt="Current market value" src="/pages/img/icons/service-valuation.png" data-was-processed="true" />
													</div>
												</div>
												<h3>{t('Current market value')}</h3>
												<p>{t('MarketValueText')}</p>
											</figcaption>			
										</figure>									
									</div>
								</Col>
								<Col xs={24} md={8} className="text-center">
									<div className="service service-2">
										<figure className="effect-ming">
											<figcaption>
												<div className="icons">
													<div className="ico service-icon">
														<img data-src="/pages/img/icons/service-travel.png" className="img-responsive lazy loaded" alt="Travel time" src="/pages/img/icons/service-travel.png" data-was-processed="true" />
													</div>
												</div>
												<h3>{t('Travel time')}</h3>
												<p>{t('TravelTimeText')}</p>
											</figcaption>			
										</figure>									
									</div>
								</Col>
								<Col xs={24} md={8} className="text-center">
									<div className="service service-3">
										<figure className="effect-ming">
											<figcaption>
												<div className="icons">
													<div className="ico service-icon">
														<img data-src="/pages/img/icons/service-sell.png" className="img-responsive lazy loaded" alt="Offer" src="/pages/img/icons/service-sell.png" data-was-processed="true" />
													</div>
												</div>
												<h3>{t('Offer')}</h3>
												<p>{t('OfferText')}</p>
											</figcaption>			
										</figure>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</section>
				<section className="info container-fluid center">
					<div className="container">
						<Row gutter={[16, 16]} justify="center" align="top">
							<Col xs ={24} lg={16} xl={18} className="text-left">
								<div className="info-txt">
									<h2 className="yellow">{t('INFO_INTRO')}</h2>
								</div>
							</Col>
							<Col xs ={24} lg={8} xl={6} className="text-right info-buttons">
								<Row gutter={[16, 16]} align="top">
									<Col xs={24} sm={12} lg={24} className="text-right">
										<a href={t('MORE_INFO_URL')}>
											<Button className="green">{t('More information')}</Button>
										</a>
									</Col>
									<Col xs={24} sm={12} lg={24} className="text-right">
										<a href={t('FAQ_URL')}>
											<Button className="yellow">{t('Frequently asked questions')}</Button>
										</a>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</section>
				<section className="login container center" id="loginId">
					<Row gutter={[16, 16]} justify="center" align="top">
						<Col xs ={24} lg={12} className="agent text-left">
							<h2 className="green">{t('Business login')}</h2>
							<div className="card text-left">
								<span dangerouslySetInnerHTML={
									{__html: t('BUSINESS_LOGIN_INTRO', {interpolation: {escapeValue: false}})}
								}
								>
								</span>
								<Row gutter={[16, 16]} className="login-buttons" justify="center" align="top">
									<Col className="text-right">
										<Button className="blue" onClick={this.userLogin}>{t('Login')}</Button>
									</Col>
									<Col className="text-right">
										<a href={t('SUBSCRIPTION_URL')}>
											<Button className="green">{t('Subscription')}</Button>
										</a>
									</Col>
									<Col className="text-right">
										<a href={t('NOT_MVA_BROKER_URL')}>
											<Button className="yellow">{t('Not an MVA Broker')}</Button>
										</a>
									</Col>
								</Row>								
							</div>
						</Col>
						<Col xs ={24} lg={12} className="buyer text-left">
							<h2 className="yellow">{t('Guest code login')}</h2>
							<div className="card text-left">
								<span dangerouslySetInnerHTML={
									{__html: t('GUEST_CODE_INTRO', {interpolation: {escapeValue: false}})}
								}
								>
								</span>
								<Row gutter={[16, 16]} className="login-buttons" justify="center" align="top">
									<Col className="text-right">
										<Button className="blue" onClick={() => this.setModal1Visible(true)}>{t('I have a login code')}</Button>
										<Modal
											title={t("Code activation")}
											centered
											visible={this.state.modal1Visible}
											onOk={() => this.setModal1Visible(false)}
											onCancel={() => this.setModal1Visible(false)}
											footer={null}
										>
											<div className="login-buttons text-center">
												<p className="text-left ">{t('LOGIN_CODE_INTRO')}</p>
												<Input
													onPasteCapture={this.codeCopied}
													maxLength={4}
													name='code1'
													value={this.state.code1}
													onChange={(e) => {
														if (/^[A-Za-z]*$/g.test(e.target.value)) {
															this.setState({code1: e.target.value.toUpperCase()})
														}
													}}
													ref={(ref) => this.scratchCode1 = ref}
												/>
												<MarginSpan>-</MarginSpan>
												<Input
													onPasteCapture={this.codeCopied}
													maxLength={4}
													name='code2'
													value={this.state.code2}
													onChange={(e) => {
														if (/^[A-Za-z]*$/g.test(e.target.value)) {
															this.setState({code2: e.target.value.toUpperCase()})
														}
													}}
													ref={(ref) => this.scratchCode2 = ref}
												/>
												<MarginSpan>-</MarginSpan>
												<Input
													onPasteCapture={this.codeCopied}
													maxLength={4}
													name='code3'
													value={this.state.code3}
													onChange={(e) => {
														if (/^[A-Za-z]*$/g.test(e.target.value)) {
															this.setState({code3: e.target.value.toUpperCase()})
														}
													}}
													ref={(ref) => this.scratchCode3 = ref}
												/>
												<Button className="blue" onClick={() => this.guestLogin(this.state, t)}>{t('Activate code')}</Button>
											</div>
										</Modal>										
									</Col>
									<Col className="text-right">
										<a href={t('MVA_BROKERS_URL')}>
											<Button className="green">{t('MVA Brokers')}</Button>
										</a>
									</Col>
									<Col className="text-right">
										<Button className="yellow" onClick={() => this.setModal2Visible(true)}>{t('Example')}</Button>
										<Modal
											className="white-modal"
											centered
											visible={this.state.modal2Visible}
											onOk={() => this.setModal2Visible(false)}
											onCancel={() => this.setModal2Visible(false)}
											footer={null}
											title={null}
										>
											<ReactPlayer 
												url={t('instructionModal.videoUrl')}
												playing={this.state.modal2Visible}
											/>
										</Modal>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</section>
				<footer>
					<div className="copyright-footer">
						<div className="container">
							<Row gutter={[16, 16]} justify="center" align="top">
								<Col xs ={24} className="text-left">
									<div className="login-logos">
										<StyledLogoContainer>
											<a href="https://www.mva.nl/" className="svg" target="_blank" rel="noopener noreferrer"><MvaIcon /></a>
											<a href="https://www.momentum-technologies.nl/" className="svg" target="_blank" rel="noopener noreferrer"><LogoIcon/></a>
											<a href="https://www.clearstone.nl/" className="svg" target="_blank" rel="noopener noreferrer"><ClearstoneIcon/></a>
											<span
												dangerouslySetInnerHTML={
													{__html: t('Based on the open-source MapitOut application', {interpolation: {escapeValue: false}})}
												}
												>
											</span>
										</StyledLogoContainer>
									</div>
								</Col>
								<Col xs ={24} md={19} className="text-left copyright">
									<p
										dangerouslySetInnerHTML={
											{__html: t('copyrightBar', {interpolation: {escapeValue: false}})}
										}
										>
									</p>
								</Col>
								<Col xs ={24} md={5} className="text-right contact-icons">
									{/* <a href='tel:+31153801685'><PhoneOutlined /></a> */}
									<a href='mailto:homelocator@momentum-technologies.nl'><MailOutlined /></a>
								</Col>
							</Row>
						</div>
					</div>
				</footer>
				<div className="cookie-bar">
					<Row>
						{this.state.cookie ?
							(<StyledCookie>
							<div>
								<p
									dangerouslySetInnerHTML={
									{__html: t('cookieBar', {interpolation: {escapeValue: false}})}}
								></p>
								<Button
									icon={<CloseCircleOutlined />}
									onClick={() => {  this.props.cookies.set('cookie', false, {path: '/'}); this.setState({cookie: false});}}
								>
								</Button>
							</div>
						</StyledCookie>)
						:
						null}
					</Row>
				</div>
			</CenteredDiv>
		)
	}

	public componentDidMount() {
		document.body.style.overflow='auto'
		if (this.isAuthenticated()) {
			getHistory().push('/app')
		}
	}

	componentDidUpdate() {
		if (this.isAuthenticated()) {
			getHistory().push('/app')
		}
	}

	private codeCopied(e) {
		const code = e.clipboardData.getData('Text').replace(/[^a-zA-Z-]+/g, '')
		let codeSplit

		if (code.indexOf('-') === -1) 
			codeSplit = code.match(/.{1,4}/g,)
		else
			codeSplit = code.split('-')

		this.setState({
			code1: codeSplit[0] ? codeSplit[0].toUpperCase() : '',
			code2: codeSplit[1] ? codeSplit[1].toUpperCase() : '',
			code3: codeSplit[2] ? codeSplit[2].toUpperCase() : ''
		})
	}

	private guestLogin(state, t) {
		const code = state.code1 + state.code2 + state.code3
		getCode(code).then(r => {
			if (r.statusCode == 404) {
				message.warning(t('Wrong scratch code'), 15)
			} else if (r.statusCode == 410) {
				message.warning(t('Scratch code expired'), 15)
			} else if (r.statusCode == 200) {
				window.location.href = r.identityUrl
			} else {
				message.error(t('Sorry, something went wrong'), 15)
			}
		})
	}

	private userLogin() {
		// tslint:disable-next-line: no-console
		getHistory().push('/app')
	}

	private changeLng = (lng) => {
	  i18n.changeLanguage(lng)
  	}

	private isAuthenticated = () => {
		const oidcStorage = JSON.parse(
			sessionStorage.getItem(
				`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`,
			),
		)

		return !!oidcStorage && !!oidcStorage.access_token
	}

	private handleNextFocus() {
		if (this.state.code2.length == 4) {
			this.scratchCode3.focus()
		} else if (this.state.code1.length == 4) {
			this.scratchCode2.focus()
		}
	}
}

const splitEvery = (str, n) => {
    let arr = new Array;
    for (let i = 0; i < str.length; i += n)
        arr.push(str.substr(i, n));
    return arr;
}

const IndexCookies = withCookies(Component) as any

const PageBrokers = withTranslation()(IndexCookies)

export {PageBrokers}
