import { ActionType } from './actions'
import { Reducer } from 'redux'
import { Tooltip } from './lib/Tooltip'

export interface State {
	zoom: number
	overlapVisible: boolean
	tooltip: Tooltip | null
	primaryEducationVisible: boolean
	secondaryEducationVisible: boolean,
	mteOverlay: boolean,
	mteCard: string | null,
	geoJsonData: any,
	geoJsonDataMarkers: any,
	legend: string[],
	lastInfoWindowId: string,
	bounds: google.maps.LatLngBounds | null
}

const initialState: State = {
	zoom: 10,
	overlapVisible: false,
	tooltip: null,
	primaryEducationVisible: false,
	secondaryEducationVisible: false,
	mteOverlay: false,
	mteCard: null,
	geoJsonData: null,
	geoJsonDataMarkers: null,
	legend: [],
	lastInfoWindowId: null,
	bounds: null
}

export type ActionDispatch = SetZoomLevel
	| SetOverlapState
	| SetTooltip
	| SetPrimaryEducationVisibility
	| SetSecondaryEducationVisibility
	| SetMteOverlayVisibility
	| SetMteCardVisibility
	| SetGeoJsonData
	| SetGeoJsonDataMarkers
	| SetLegend
	| SetLastCheckedInfoWindowId
	| SetBounds

export const reducer: Reducer<State, ActionDispatch> = (state: State = initialState, action: ActionDispatch) => {
	switch (action.type) {
		case ActionType.SetZoomLevel:
			return reduceSetZoomLevel(state, action)
		case ActionType.SetOverlapState:
			return reduceSetOverlapState(state, action)
		case ActionType.SetTooltip:
			return reduceSetTooltip(state, action)
		case ActionType.SetPrimaryEducationVisibility:
			return reduceSetPrimaryEducationVisibility(state, action)
		case ActionType.SetSecondaryEducationVisibility:
			return reduceSetSecondaryEducationVisibility(state, action)
		case ActionType.SetMteOverlayVisibility:
			return reduceSetMteOverlayVisibility(state, action)
		case ActionType.SetMteCardVisibility:
			return reduceSetMteCardVisibility(state, action)
		case ActionType.SetGeoJsonData:
			return reduceSetGeoJsonData(state, action)
		case ActionType.SetGeoJsonDataMarkers:
			return reduceSetGeoJsonDataMarkers(state, action)
		case ActionType.SetLegend:
			return reduceSetLegend(state, action)
		case ActionType.SetLastCheckedInfoWindowId:
			return reduceSetLastCheckedInfoWindowId(state, action)
		case ActionType.SetBounds:
			return reduceSetBounds(state, action)
		default:
			return state
	}
}

interface SetZoomLevel {
	type: ActionType.SetZoomLevel
	data: number
}

const reduceSetZoomLevel = (state: State, action: SetZoomLevel) => {
	return {
		...state,
		zoom: action.data
	}
}

interface SetOverlapState {
	type: ActionType.SetOverlapState
	data: boolean
}

const reduceSetOverlapState = (state: State, action: SetOverlapState) => {
	return {
		...state,
		overlapVisible: action.data
	}
}

interface SetTooltip {
	type: ActionType.SetTooltip
	data: Tooltip | null
}

const reduceSetTooltip = (state: State, action: SetTooltip) => {
	return {
		...state,
		tooltip: action.data
	}
}

interface SetPrimaryEducationVisibility {
	type: ActionType.SetPrimaryEducationVisibility
	data: boolean
}

const reduceSetPrimaryEducationVisibility = (state: State, action: SetPrimaryEducationVisibility) => {
	return {
		...state,
		primaryEducationVisible: action.data
	}
}

interface SetSecondaryEducationVisibility {
	type: ActionType.SetSecondaryEducationVisibility
	data: boolean
}

const reduceSetSecondaryEducationVisibility = (state: State, action: SetSecondaryEducationVisibility) => {
	return {
		...state,
		secondaryEducationVisible: action.data
	}
}

interface SetMteOverlayVisibility {
	type: ActionType.SetMteOverlayVisibility
	data: boolean
}

const reduceSetMteOverlayVisibility = (state: State, action: SetMteOverlayVisibility) => {
	return {
		...state,
		mteOverlay: action.data
	}
}

interface SetMteCardVisibility {
	type: ActionType.SetMteCardVisibility
	data: string | null
}

const reduceSetMteCardVisibility = (state: State, action: SetMteCardVisibility) => {
	return {
		...state,
		mteCard: action.data
	}
}

interface SetGeoJsonData {
	type: ActionType.SetGeoJsonData
	data: any
}

const reduceSetGeoJsonData = (state: State, action: SetGeoJsonData) => {
	return {
		...state,
		geoJsonData: action.data
	}
}

interface SetGeoJsonDataMarkers {
	type: ActionType.SetGeoJsonDataMarkers
	data: any
}

const reduceSetGeoJsonDataMarkers = (state: State, action: SetGeoJsonDataMarkers) => {
	return {
		...state,
		geoJsonDataMarkers: action.data
	}
}

interface SetLegend {
	type: ActionType.SetLegend
	data: any
}

const reduceSetLegend = (state: State, action: SetLegend) => {
	return {
		...state,
		legend: action.data
	}
}

interface SetLastCheckedInfoWindowId {
	type: ActionType.SetLastCheckedInfoWindowId
	data: any
}

const reduceSetLastCheckedInfoWindowId = (state: State, action: SetLastCheckedInfoWindowId) => {
	return {
		...state,
		lastInfoWindowId: action.data
	}
}

interface SetBounds {
	type: ActionType.SetBounds
	data: any
}

const reduceSetBounds = (state: State, action: SetBounds) => {
	return {
		...state,
		bounds: action.data
	}
}




