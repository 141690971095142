import { colors } from '../constants'

export const colorList = [
	colors.red,
	colors.yellow,
	colors.blue,
	colors.green,
	colors.purple,
	colors.orange,
	colors.white
]
