import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import styled, { css } from 'styled-components'
import CopyToClipboard from 'react-copy-to-clipboard'
import { withTranslation } from 'react-i18next'
import { withCookies, Cookies } from 'react-cookie';
import { Modal, Button, message, Menu } from 'antd';
import i18n from "../../../i18n/i18next";
import { inIframe } from "../../../utils/inIframe";

import { ReduxState, getTravelTimes, removeTravelTime, purgeTravelTimes, setOverlapState, setMteOverlay, setGeoJsonData, setGeoJsonDataMarkers, setLegend, setIntersectionActive } from 'store'
import { TravelTimeAbstraction, MTeHouseTypeSelectedAbstraction } from 'interfaces'
import { AddIcon, InfoIcon, CompareIcon, LayersIcon, LinkIcon, LogoIcon, MvaIcon, ClearstoneIcon, TravelTimeIcon, MetropoolIcon, PointerIcon } from 'icons'
import { colorList, hexColorToRGBA } from 'utils'
import { shadows } from '../../../constants'

import { TravelCard } from './TravelCard'
import { MTeCard } from './MTeCard'
import { EditTravelTime } from './EditTravelTime'
import { MTeOverlay } from './MTeOverlay'
import { Loader } from './Loader'
import { Profile } from '../../components/Profile'
import ReactPlayer from 'react-player/lazy'

const StyledUIContainer = styled.main<{ menuActive: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	box-sizing: border-box;
	height: 100%;
	display: flex;
	flex-direction: column;
	pointer-events: none;
	
	@media (max-width: 900px) {
		transition: left 300ms;
		left: ${(props) => props.menuActive ? 0 : '-100%'};
	}
`

const StyledUIContainerInner = styled.div`
	flex: 1;
	display: flex;
    flex-direction: column;

	@media (max-width: 900px) {
		padding-left: 100vw;
	}
`

const StyledUIContainerInnerMainContent = styled.div`
	flex: 1;
	padding: 1rem;
	position: relative;
`

const StyledFilterContainer = styled.div`
	width: 27rem;
	max-width: 100vw;
	box-sizing: border-box;
	
	padding: 0 1rem 0;
`

const StyledLogosContainer = styled.div`
    padding-left: 5rem;
	z-index: 1;
	line-height: 20px;
	pointer-events: auto;
	width: 455px;

	a.svg {
		transition: all .6s ease-in-out;
		display: inline-block;
	}

	a.svg:hover {
		filter: grayscale(100%);
    	-webkit-filter: grayscale(100%);
	}
`

const StyledLogoContainerIndividual = styled.div`    
    z-index: 10;
    margin-left:10rem;
    padding-left:10rem;
`

const StyledLogoContainer = styled.div`
	z-index: 10;
	position: fixed;
    right: 10px;
	top: 10px;
	
	svg {
		width: 175px;
		height: auto;
	}

	@media (max-width: 900px) {
		display: none;
	}
`

const StyledActionContainer = styled.div`
	margin-top: .5rem;
    flex-direction: column;
    align-items: flex-start;
	display: inline-flex;
	
	p {
		font-family: HelveticaNeueMedium,sans-serif !important;
    	color: rgba(0,0,0,0.8);
	}

	.compare {
		position: absolute;
		bottom: 0;
	}

	/* @media (max-width: 900px) {
		.add,
		.show {
			position: absolute;
		}
		.add {
			top: 105px;
		}
		.show {
			top: 150px;
		}
	} */
`

const StyledAction = styled.div<{ isDisabled?: boolean }>`
	position: relative;
	cursor: ${(props) => props.isDisabled ? 'default' : 'pointer'};
	transition: 100ms;
	opacity: ${(props) => props.isDisabled ? .5 : 1};
	margin: .25rem 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	z-index: 0;
	
	pointer-events: auto;
	
	@media (max-width: 900px) {
		margin: .25rem 0;
		
		p {
			display: none;
		}
	}
	
	${(props) => !props.isDisabled && css`
		:before {
			content: '';
			position: absolute;
			top: 10%;
			height: 80%;
			left: 1.25rem;
			background-color: rgba(255, 255, 255, 0.75);
			border-radius: 99px;
			transition: 200ms;
			width: 0;
			z-index: -1;
		}
		
		@media (min-width: 900px) {
			:hover:before {
				width: 100%;
			}
		}
	`};
`

const StyledActionIcon = styled.div<{ isDisabled?: boolean, isActive?: boolean }>`
	border-radius: 99px;
	border: 3px solid rgba(23,163,69,1);
	background-color: ${(props) => props.isActive ? '#fff' : '#fff'};
	color: rgba(23,163,69,1);
	margin-right: .5em;
	${shadows.normal};
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 100ms;
	
	${(props) => !props.isDisabled && css`
		${StyledAction}:hover & {
			transform: scale(1.1);
		}
	`}
`

const StyledCopyNotification = styled.div`
	padding: .125rem .25rem;
	border-radius: 99px;
	position: absolute;
	bottom: -1rem;
	animation-name: anim;
	animation-duration: 3000ms;
	white-space: nowrap;
	background-color: #fff;
	
	@media (max-width: 900px) {
		bottom: 50%;
		transform: translateY(50%);
	}
	
	p {
		font-size: .625rem !important;
	}

	@keyframes anim {
		0% {
			left: 2rem;
			opacity: 0;
		}
		10% {
			left: 3rem;
			opacity: 1;
		}
		90% {
			left: 3rem;
			opacity: .75;
		}
		100% {
			left: 2rem;
			opacity: 0;
		}
	}
`

const StyledLoaderContainer = styled.div`
	margin: 1rem 0 1rem 1rem;
`

const sharedWrapperVisibilityAnimation = css<{ visible: boolean, mine: boolean }>`
	pointer-events: ${(props) => props.visible ? 'auto' : 'none'};
	transition: 500ms;
	
	& > * {
		transition: 500ms;
	}
	
	${(props) => props.visible ? css`
		max-height: 290px;
		max-width: 885px;
		
		& > * {
			opacity: 1;
		}

		@media (min-width: 901px) and (max-width: 1050px) {
			max-width: 685px;
		}
	` : css`
		max-height: 0;
		
		& > * {
		opacity: 0;
		}
	`}

	${(props) => props.mine ? css`
		max-height: 99999px;	
		
	` : css`		
	`}
`



const StyledCardContainer = styled.div<{ visible: boolean, mine: boolean }>`	
	${sharedWrapperVisibilityAnimation};
	
	width: 330px;
	max-width: 100vw;
`

const StyledEditWrapper = styled.div<{ visible: boolean }>`
	${sharedWrapperVisibilityAnimation};

		@media (max-width: 900px) {
		position: absolute;
		width: 100vw;
		top: 0;
		left: 0;
		padding: 5rem 5rem 0 5rem;
    	box-sizing: border-box;
    	z-index: 0;
    	
    	:before {
    		content: '';
    		position: absolute;
    		top: 0;
    		left: 0;
    		width: 100%;
    		height: 100%;
    		z-index: -1;
		}
		&.show-houses {
			top: initial;
			bottom: 45px;			
			padding-top: 0 !important;
			z-index: 2;
		}
	}
`

const StyledEntryTravelTimeContainer = styled.div`
	position: absolute;
	left: 0;
	width: 100%;
	
	@media (min-width: 900px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 4rem;
	}
	
	@media (max-width: 900px) {
		padding: 6rem 1rem 0;
		box-sizing: border-box;
		z-index: 0;
		
		:before {
    		content: '';
    		position: absolute;
    		top: 0;
    		left: 0;
    		width: 100%;
    		height: 100%;
    		background: linear-gradient(to bottom, #D9F0F3 0%, #D9F0F3 15%, ${hexColorToRGBA('#D9F0F3', 0)} 50%);
    		z-index: -1;
    	}
	}
`

const StyledOnboardingTooltipContainer = styled.div`
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const StyledOnboardingTooltip = styled.div`
	display: flex;
	margin: 0 1rem 2rem;
	margin-left: 5%;
	background-color: rgba(0, 0, 0, .6);
	border-radius: 99px;
	min-height: 40px;
	flex-direction: row;
	align-items: center;
	color: white;
	padding: 0 .75rem;
	
	p {
		font-family: Roboto, Arial, sans-serif !important;
    	line-height: 17px;
	}

	@media (max-width: 900px) {
		padding: .25rem .75rem;
	}
	
	& > *:first-child {
		min-width: 1rem;
		margin-right: .5rem;
	}
`

interface StateProps {
	loading: ReduxState['travelTime']['loading']
	travelTimes: ReduxState['travelTime']['travelTimes']
	overlap: ReduxState['travelTime']['overlap']
	overlapVisible: ReduxState['application']['overlapVisible']
	mteOverlay: ReduxState['application']['mteOverlay']
	mteCard: ReduxState['application']['mteCard']
	intersectionActive: ReduxState['travelTime']['intersectionActive']
}
interface DispatchProps {
	getTravelTimes: typeof getTravelTimes
	removeTravelTime: typeof removeTravelTime
	purgeTravelTimes: typeof purgeTravelTimes
	setOverlapState: typeof setOverlapState,
	setMteOverlay: typeof setMteOverlay,
	setGeoJsonData: typeof setGeoJsonData,
	setGeoJsonDataMarkers: typeof setGeoJsonDataMarkers,
	setLegend: typeof setLegend,
	setIntersectionActive: typeof setIntersectionActive
}
interface Props {
	changeClickOnMap: ((value: boolean) => void)
	isCurrentlyAddingNewTravelTime: boolean
	changeisCurrentlyAddingNewTravelTime: ((value: boolean) => void)
	changeActiveStep: ((step: string) => void)
	instructionStepActive: string
}
type PropsUnion = StateProps & DispatchProps & Props & any

interface State {
	currentTravelTimeEditing: string | null
	currentTravelTimeEditSaving: string | null
	justCopied: boolean,
	isCurrentlyAddingNewHouses: boolean,
	mteCard: MTeHouseTypeSelectedAbstraction | null,
	openModal: boolean,
	showInstructions: boolean,
	playerActive: boolean,
	intersectionModal: boolean
}

export class Component extends React.Component<PropsUnion, State> {

	constructor(props){
		super(props)
	}

	public readonly state: State = {
		currentTravelTimeEditing: null,
		currentTravelTimeEditSaving: null,
		justCopied: false,
		isCurrentlyAddingNewHouses: false,
		mteCard: null,
		openModal: this.props.cookies.get('openModal') === undefined ? true : false,
		showInstructions: false,
		playerActive: false,
		intersectionModal: false
	}
	public addressFieldRef = React.createRef<any>()

	public componentDidUpdate(prevProps: Readonly<PropsUnion>, prevState: Readonly<State>, snapshot?: any): void {
		if (this.state.currentTravelTimeEditSaving) {
			this.setState({
				currentTravelTimeEditSaving: null
			})
		}
		if (this.props.intersectionActive !== prevProps.intersectionActive && this.props.intersectionActive) {
			this.setState({
				intersectionModal: true
			})
		}
	}

	public render() {
		const {openModal, showInstructions} = this.state
		const {t, cookies} = this.props;
		return (
			<StyledUIContainer menuActive={!!this.state.currentTravelTimeEditing || this.props.isCurrentlyAddingNewTravelTime}>
				<Modal
					closable={false}
					maskClosable={false}
					title={t("instructionModal.title")}
					visible={openModal}
					onOk={() => this.setState({openModal: false})}
					onCancel={() => {this.setState({openModal: false})}}
					centered
					footer={[
						<Button key="back" className="yellow" onClick={() => {this.props.cookies.set('openModal', false, {path: '/'}); this.setState({openModal: false, showInstructions: false})}}>
						  {t("Don't show this again")}
						</Button>,
						<Button key="submit" className="green" onClick={() => this.setState({openModal: false, showInstructions: true})}>
						  {t("yes please")}
						</Button>,
					  ]}
				>
					<p className="text-left" dangerouslySetInnerHTML={
									{__html: t('instructionModal.FirstParagraph', {interpolation: {escapeValue: false}})}
					}></p>
					<br />
					<p className="text-left" dangerouslySetInnerHTML={
									{__html: t('instructionModal.doYouNeedHelp', {interpolation: {escapeValue: false}})}
					}></p>
				</Modal>
				<Modal
					className="white-modal"
					visible={this.state.playerActive}
					onOk={() => this.togglePlayer(false)}
					onCancel={() => this.togglePlayer(false)}
					centered
					footer={null}
					title={null}
				>
					<ReactPlayer 
						url={t('instructionModal.videoUrl')} 
						playing={this.state.playerActive}
					/>
				</Modal>
				<div 
					className="arrows"
				>
					{this.state.showInstructions && this.renderArrows()}
				</div>
				<StyledUIContainerInner>
					<StyledUIContainerInnerMainContent>
						{this.renderTravelTimes()}
						{this.renderMTeCard()}
						{this.renderAddingNewHouses()}
						{this.props.travelTimes === null ? this.renderActiveNew() : this.props.isCurrentlyAddingNewTravelTime ? this.renderActiveNew() : null}
						{this.renderLoader()}
						{this.renderMapActions()}
						<StyledCardContainer
							visible={true}
							mine={true}
						>
							<Profile 
								showInstructions={showInstructions}
								step={this.props.instructionStepActive} 
								toggleInstruction={this.toggleInstruction}
								togglePlayer={this.togglePlayer}
								exitHelp={this.exitHelp}
							/>
						</StyledCardContainer>
					</StyledUIContainerInnerMainContent>
					<StyledLogosContainer>
						<a href="https://www.mva.nl/" className="svg" target="_blank" rel="noopener noreferrer"><MvaIcon /></a>
						<a href="https://www.momentum-technologies.nl/" className="svg" target="_blank" rel="noopener noreferrer"><LogoIcon /></a>
						<a href="https://www.clearstone.nl/" className="svg" target="_blank" rel="noopener noreferrer"><ClearstoneIcon /></a>
						<a href="https://traveltime.com/" className="svg" target="_blank" rel="noopener noreferrer"><TravelTimeIcon /></a>
					</StyledLogosContainer>
				</StyledUIContainerInner>
			</StyledUIContainer>
		)
	}

	public togglePlayer = (active) => {
		this.setState({
			playerActive: active
		})
	}

	public exitHelp = () => {
    this.setState({showInstructions:false})
  }

	public renderArrows(): React.ReactNode {
		switch(this.props.instructionStepActive) {
			case '0':
				return <PointerIcon  style={{display: 'none'}} className="step-1-0" />
			case '1':
				return <PointerIcon className="step-1-0" />
			case '1-1':
				if (this.props.travelTimes && this.props.travelTimes.length	>= 1) {
					const topVal = `${this.props.travelTimes.length * 88 + 5}px`
					return <PointerIcon	style={{position:	'absolute', left: '915px', top: topVal}}/>
				}
				return <PointerIcon className="step-1-1" />
			case '2':
				if (this.props.travelTimes && this.props.travelTimes.length	=== 2) {
					const topVal = `${this.props.travelTimes.length	* 45 + 145}px`
					return <PointerIcon	style={{position:	'absolute', left: '170px', top: topVal}}/>
				}
				if (this.props.travelTimes && this.props.travelTimes.length	=== 3) {
					const topVal = `${this.props.travelTimes.length	* 60 + 145}px`
					return <PointerIcon	style={{position:	'absolute', left: '170px', top: topVal}}/>
				}
				if (this.props.travelTimes && this.props.travelTimes.length	=== 4) {
					const topVal = `${this.props.travelTimes.length	* 67 + 145}px`
					return <PointerIcon	style={{position:	'absolute', left: '170px', top: topVal}}/>
				}
				if (this.props.travelTimes && this.props.travelTimes.length	=== 5) {
					const topVal = `${this.props.travelTimes.length	* 72 + 145}px`
					return <PointerIcon	style={{position:	'absolute', left: '170px', top: topVal}}/>
				}
				if (this.props.travelTimes && this.props.travelTimes.length	=== 6) {
					const topVal = `${this.props.travelTimes.length	* 74 + 145}px`
					return <PointerIcon	style={{position:	'absolute', left: '170px', top: topVal}}/>
				}
				return <PointerIcon className="step-2-0" />
			case '2-1':
				if (this.props.travelTimes && this.props.travelTimes.length	>= 1) {
					const topVal = `${this.props.travelTimes.length * 88 + 15}px`
					return <PointerIcon	style={{position:	'absolute', left: '540px', top: topVal}}/>
				}
				return <PointerIcon className="step-2-1" />
			case '3':
				return <PointerIcon className="step-3-0" />
			default:
				return null
		}
	}

	public toggleInstruction = (show: boolean) => {
		if (show)
			this.setState({
				showInstructions: show
			})
		else
			this.setState({
				showInstructions: show
			})
	}

	public setStep = (step: string) => {
		this.props.changeActiveStep(step)
	}

	public cancelMteOverlay = () => {
		this.props.setMteOverlay(!this.props.mteOverlay)
		this.setState({
			mteCard: null,
			isCurrentlyAddingNewHouses: false
		})
		this.setStep('2')
	}

	private changeLng = (lng) => {
	  i18n.changeLanguage(lng)
  }

	public removeMteCard = () => {
		this.props.setGeoJsonDataMarkers(null);
		this.setState({
			mteCard: null
		})
		this.props.setGeoJsonData(null);
		this.props.setLegend([]);
	}

	private modalOk(e) {
		this.setState({openModal: false})
	}

	private modalCancel(e) {
		this.props.cookies.set('openModal', false, {path: '/'})
	}

	private renderAddingNewHouses() {
		return (
			<>
				<StyledEditWrapper
					className="show-houses"
					visible={this.state.isCurrentlyAddingNewHouses}
				>
					<MTeOverlay
						onFinish={(v: MTeHouseTypeSelectedAbstraction) => { this.addMTeCard(v) }}
						onCancel={() => this.cancelMteOverlay()}
						setStep={this.setStep}
					/>
				</StyledEditWrapper>

			</>
		)
	}

	private renderMTeCard() {
		return (
			<>
				{this.state.mteCard != null ?
					<React.Fragment key={this.state.mteCard.value}>
						<StyledCardContainer
							visible={this.state.mteCard != null}
							mine={true}
							onClick={() => window.innerWidth <= 900}
						>
							<MTeCard
								color={colorList[6]}
								onDelete={() => this.removeMteCard()}
								onEdit={() => console.log()}
								title={this.state.mteCard.title}
								value={this.state.mteCard.value}
								travelTimes={this.props.travelTimes}
							/>
						</StyledCardContainer>
						{/* <StyledEditWrapper
					visible={travelTime.res.search_id === this.state.currentTravelTimeEditing}
				>
					<EditTravelTime
						color={colorList[i]}
						onFinish={(v: TravelTimeAbstraction) => this.save(travelTime.res.search_id, v)}
						onCancel={() => this.setState({
							currentTravelTimeEditing: null,
							currentTravelTimeEditSaving: null
						})}
						onDelete={() => this.removeTravelTime(travelTime.res.search_id)}
						{...travelTime}
					/>
				</StyledEditWrapper> */}
					</React.Fragment> : ''}
			</>
		)
	}

	private renderTravelTimes() {
		return (
			<>
				{this.props.travelTimes && this.props.travelTimes.map((travelTime, i) => (
					<React.Fragment key={travelTime.res.search_id}>
						<StyledCardContainer
							mine={false}
							visible={travelTime.res.search_id !== this.state.currentTravelTimeEditing}
							onClick={() => window.innerWidth <= 900 && this.edit(travelTime.res.search_id)}
						>
							<TravelCard
								color={colorList[i]}
								onDelete={() => this.removeTravelTime(travelTime.res.search_id)}
								onEdit={() => this.edit(travelTime.res.search_id)}
								{...travelTime}
							/>
						</StyledCardContainer>
						<StyledEditWrapper
							visible={travelTime.res.search_id === this.state.currentTravelTimeEditing}
						>
							<EditTravelTime
								setStep={this.setStep}
								color={colorList[i]}
								onFinish={(v: TravelTimeAbstraction) => this.save(travelTime.res.search_id, v)}
								onCancel={() => {this.setState({
									currentTravelTimeEditing: null,
									currentTravelTimeEditSaving: null
									})
									this.props.changeClickOnMap(false)
								}}
								onDelete={() => this.removeTravelTime(travelTime.res.search_id)}
								{...travelTime}
							/>
						</StyledEditWrapper>
					</React.Fragment>
				))}
			</>
		)
	}

	private renderLoader() {
		if (!this.props.loading) return null

		return (
			<StyledLoaderContainer>
				<Loader />
			</StyledLoaderContainer>
		)
	}

	private renderMapActions() {
		const {t} = this.props
		return (
			<StyledActionContainer>
				<Modal
					visible={this.props.intersectionActive ? false : this.state.intersectionModal}
					centered
					footer={[
						<Button key="submit" className="blue" onClick={() => this.setState({intersectionModal: false})}>
						  Ok
						</Button>,
					]}
				>
					<p>{t('There are no intersections')}</p>
				</Modal>
				<StyledAction
					className="add"
					isDisabled={!!(this.props.travelTimes && this.props.travelTimes.length >= 6)}
					onClick={() => {!(this.props.travelTimes && this.props.travelTimes.length >= 6)
						&& this.setState({
							currentTravelTimeEditing: null,
							currentTravelTimeEditSaving: null
						})
						this.setStep('1-1')
						this.props.changeisCurrentlyAddingNewTravelTime(true)
						this.props.changeClickOnMap(true)
					}}
				>
					<StyledActionIcon>
						<AddIcon />
					</StyledActionIcon>
					<p>{t('Add new location')}</p>
				</StyledAction>
				{
					this.state.mteCard == null ?
						<StyledAction
							className="show"
							isDisabled={this.props.intersectionActive ? !(this.props.travelTimes && this.props.travelTimes.length !== 0) ?  false : false : true}
							onClick={() => {
								if(!(this.props.travelTimes && this.props.travelTimes.length !== 0)){
									message.info(t('You need to select address'))
									return
								}
								if (!this.props.intersectionActive) {
									return
								}
								this.props.setMteOverlay(!this.props.mteOverlay);
								this.setState({
									isCurrentlyAddingNewHouses: !this.props.mteOverlay,
								})
								this.setStep('2-1')
							}}
						>
							<StyledActionIcon
								isActive={this.props.mteOverlay}
							>
								<LayersIcon />
							</StyledActionIcon>
							<p>{this.props.mteOverlay
								? t('Exit houses')
								: t('Show houses')}</p>
						</StyledAction> : ""
				}
				{inIframe() ? 
					null
					:
					(
						<StyledAction
							className="compare"
							onClick={()=> window.open("#")}
						>
							<StyledActionIcon>
								<CompareIcon />
							</StyledActionIcon>
							<p>{t('Duplicate Window')}</p>
						</StyledAction>
					)
				}
				
			</StyledActionContainer>
		)
	}

	private renderActiveNew() {
		return (
			<StyledEditWrapper visible={this.props.isCurrentlyAddingNewTravelTime}>
				<EditTravelTime
					setStep={this.setStep}
					color={colorList[(this.props.travelTimes && this.props.travelTimes.length) || 0]}
					onFinish={(v: TravelTimeAbstraction) => { this.addTravelTime(v) }}
					onCancel={() => {
						this.setStep(this.props.travelTimes ? '2' : '1')
						this.props.changeisCurrentlyAddingNewTravelTime(false)
						this.props.changeClickOnMap(false)
					}}
				/>
			</StyledEditWrapper>
		)
	}

	private edit = (id: string) => {
		this.props.setGeoJsonDataMarkers(null)
		this.setState({
			currentTravelTimeEditing: id
		}, () => this.setFocus())
		this.removeMteCard()
	}

	private setFocus() {
		if (this.addressFieldRef.current) {
			this.addressFieldRef.current.focus()
		} else {
			setTimeout(() => this.setFocus(), 50)
		}
	}

	private save = (id: string, travelTime: TravelTimeAbstraction) => {
		const data: TravelTimeAbstraction[] = (this.props.travelTimes || []).map((v) => {
			return id === v.res.search_id ? travelTime : {
				title: v.title,
				location: {
					lat: v.location.lat,
					lng: v.location.lng,
				},
				duration: v.duration,
				transport: v.transport
			}
		})
		this.props.getTravelTimes(data)
		this.setState({
			currentTravelTimeEditSaving: id,
			currentTravelTimeEditing: null
		})
	}

	private addTravelTime = (travelTime: TravelTimeAbstraction) => {
		const currentTravelTimes = this.props.travelTimes || []
		this.props.getTravelTimes([
			...currentTravelTimes,
			travelTime
		])
		this.props.changeisCurrentlyAddingNewTravelTime(false)
	}

	private addMTeCard = (mteCard: MTeHouseTypeSelectedAbstraction) => {
		this.props.setMteOverlay(!this.props.mteOverlay)
		this.setState({
			mteCard: mteCard,
			isCurrentlyAddingNewHouses: false
		})
	}


	private removeTravelTime = (id: string) => {
		if(this.props.travelTimes && this.props.travelTimes.length > 1)
			this.setStep('2')
		if(this.props.travelTimes && this.props.travelTimes.length === 1)
			this.setStep('1')
		this.removeMteCard()
		this.props.changeClickOnMap(false)
		const currentTravelTimes = this.props.travelTimes || []
		const newTravelTimes = currentTravelTimes.filter((travelTime) => travelTime.res.search_id !== id)

		if (newTravelTimes.length === 0) {
			this.props.purgeTravelTimes()
			return
		}

		this.props.removeTravelTime(id)
		this.props.getTravelTimes([
			...newTravelTimes
		])
	}

	private handleCopy = () => {
		this.setState({
			justCopied: true
		}, () => setTimeout(() => this.setState({
			justCopied: false
		}), 3000))
	}

	private isMTeOverlayAvailable(): boolean {
		return !this.props.mteOverlay
	}
}

const mapStateToProps = (state: ReduxState) => ({
	loading: state.travelTime.loading,
	travelTimes: state.travelTime.travelTimes,
	overlap: state.travelTime.overlap,
	overlapVisible: state.application.overlapVisible,
	mteOverlay: state.application.mteOverlay,
	mteCard: state.application.mteCard,
	intersectionActive: state.travelTime.intersectionActive
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	getTravelTimes,
	removeTravelTime,
	purgeTravelTimes,
	setOverlapState,
	setMteOverlay,
	setGeoJsonData,
	setGeoJsonDataMarkers,
	setLegend,
	setIntersectionActive
}, dispatch)

const InteractiveOverlayCookies = withCookies(Component) as any

const InteractiveOverlayTranslation = withTranslation()(InteractiveOverlayCookies)

export const InteractiveOverlay = connect<StateProps, DispatchProps, Props, ReduxState>(
	mapStateToProps,
	mapDispatchToProps
)(InteractiveOverlayTranslation)
