import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Polygon } from 'react-google-maps'
import simplify from 'simplify-js'

import { ReduxState, getTravelTimes, setGeoJsonData, setGeoJsonDataMarkers, setLegend } from 'store'
import { bspline, colorList } from 'utils'

import geoJsonData from '../../../constants/testGeoJson.json'
import { Markers } from './index.js'

interface StateProps {
	travelTimes: ReduxState['travelTime']['travelTimes']
	overlap: ReduxState['travelTime']['overlap']
	zoom: ReduxState['application']['zoom']
	overlapVisible: ReduxState['application']['overlapVisible']
	geoJsonData: ReduxState['application']['geoJsonData']
	legend: ReduxState['application']['legend']
}
interface DispatchProps {
	getTravelTimes: typeof getTravelTimes,
	setGeoJsonData: typeof setGeoJsonData,
	setLegend: typeof setLegend
}
interface Props { }
type PropsUnion = StateProps & DispatchProps & Props

type Coordinate = { lat: number, lng: number }
interface State {
	coordinates: Array<[Coordinate[], Coordinate[]]>
}

const range = [7, 12]
export class Component extends React.Component<PropsUnion, State> {
	public readonly state: State = {
		coordinates: [[[], []]]
	}

	public shouldComponentUpdate(nextProps: Readonly<PropsUnion>, nextState: Readonly<State>, nextContext: any): boolean {
		if (
			this.props.travelTimes !== nextProps.travelTimes
			|| this.props.overlap !== nextProps.overlap
			|| this.props.overlapVisible !== nextProps.overlapVisible
		) {
			return true
		}

		if (nextProps.geoJsonData != null && nextProps.geoJsonData['features'] && (this.props.geoJsonData != nextProps.geoJsonData))
			return true;

		if (this.props.legend != nextProps.legend)
			return true;

		return !((this.props.zoom <= range[0] && nextProps.zoom <= range[0])
			|| (this.props.zoom >= range[1] && nextProps.zoom >= range[1]))
	}

	public render() {
		const { overlap, overlapVisible, travelTimes, geoJsonData } = this.props

		if (geoJsonData != null && geoJsonData['features']) {
			const features = [];
			geoJsonData['features'].map((v) => {
				features.push(v);
			});
			return (
				<>
					{features.map((feature, i) =>
						this.renderGeoPolygon(i, feature.properties.fill, feature.geometry.type, feature.geometry.coordinates, true, feature)

					)}
				</>
			)
		} else {
			return (
				<>
					{overlap && overlap.shapes.map((shape, i) =>
						shape.shell.length > 10 && this.renderPolygon(
							`overlap:${i}`,
							'#000',
							shape,
							overlapVisible
						)
					)}
					{travelTimes && travelTimes.map((travelTime, i) =>
						travelTime.res.shapes.map((shape, j) =>
							shape.shell.length > 10 && this.renderPolygon(
								`${travelTime.res.search_id}:${j}`,
								colorList[i],
								shape,
								!this.props.overlapVisible
							)
						)
					)}
				</>
			)
		}

	}

	private renderPolygon(key: string, color: string, shape: NonNullable<StateProps['overlap']>['shapes'][0], visible: boolean) {
		const coordinates = (geoJsonData as any).features[0].geometry.coordinates[0];
		const coordinatesArr = [];
		coordinates.map((coord) =>
			coordinatesArr.push({ lat: coord[1], lng: coord[0] }));
		return (
			<Polygon
				key={key}
				options={{
					clickable: false,
					strokeColor: color,
					fillColor: color,
					strokeOpacity: 1,
					strokeWeight: 2.5,
					fillOpacity: .1
				}}
				paths={[this.getSmoothShape(shape.shell),
				...shape.holes.filter((hole) => hole.length > 15).map((hole) => this.getSmoothShape(hole))
				]}
				visible={visible}
			/>
		)
	}

	private renderGeoPolygon(key: any, color: string, type: string, shape: any, visible: boolean, feautre: any) {
		if (!this.props.legend.includes(feautre['properties']['marktwaardeKlasse'])) {
			color = '#D3D3D3';
		}


		const coordinatesArr = []
		if (type == 'Polygon')
			shape.map((coord) => coord.map((anotherCord) => coordinatesArr.push({ lat: anotherCord[1], lng: anotherCord[0] })))
		else if (type == 'MultiPolygon')
			shape.map((coord) => coord.map((anotherCord) => {
				var arr = [];
				anotherCord.map((thirdCord) => {
					arr.push({
						lat: thirdCord[1], lng: thirdCord[0]
					}
					)
				}
				)
				coordinatesArr.push(arr);
			}

			)
			)
		return (
			<Polygon
				key={key}
				options={{
					clickable: false,
					strokeColor: color,
					fillColor: color,
					strokeOpacity: 1,
					strokeWeight: 2.5,
					fillOpacity: .3
				}}
				paths={coordinatesArr}
				visible={visible}
			/>
		)
	}

	private renderEmpty() {
		return ('')
	}

	private getSmoothShape(coordinates: Array<{ lat: number, lng: number }>) {
		const progress = Math.max(Math.min(1 - (this.props.zoom - range[0]) / (range[1] - range[0]), 1), 0)
		const simplifiedShape = simplify(coordinates.map((coordinate) => ({
			x: coordinate.lat,
			y: coordinate.lng
		})), 0.01 * progress * Math.min(coordinates.length / 500, 1), false)

		return bspline(
			simplifiedShape.map((coord: any) => coord.x),
			simplifiedShape.map((coord: any) => coord.y),
			0.05
		)
	}
}

const mapStateToProps = (state: ReduxState) => ({
	travelTimes: state.travelTime.travelTimes,
	overlap: state.travelTime.overlap,
	zoom: state.application.zoom,
	overlapVisible: state.application.overlapVisible,
	geoJsonData: state.application.geoJsonData,
	legend: state.application.legend
})
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	getTravelTimes,
	setGeoJsonData,
	setGeoJsonDataMarkers,
	setLegend
}, dispatch)

export const Polygons = connect<StateProps, DispatchProps, Props, ReduxState>(
	mapStateToProps,
	mapDispatchToProps
)(Component)
