import React from "react";

import { OidcConsumer } from "./index";
import Spinner from "../components/Spinner"

export const SilentRenew = () => (
  <OidcConsumer>
    {({ signinSilentCallback }) => {
            signinSilentCallback();
            return <Spinner />;
        }}
  </OidcConsumer>
);
