import React from 'react'
import {getHistory} from '../../../utils/history'

class Component extends React.Component<any, any> {

	componentDidMount(){getHistory().push('/app')}

	public render() {
		return (
      null
		)
	}

}

export {Component as SignIn}
