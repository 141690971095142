import React from 'react'
import {Row, Col, Collapse, Button} from 'antd'
import {withTranslation} from 'react-i18next'
// tslint:disable-next-line: no-submodule-imports
import 'antd/lib/message/style'
import { CloseOutlined } from '@ant-design/icons';

const { Panel } = Collapse

interface State {
  width: number
}

const convertStepIntoNumber = (step) => {
  const val = parseInt(step.charAt(0))
  return val == 0 ? 1 : val
}

class Component extends React.Component<any, State> {

  public readonly state: State = {
    width: window.innerWidth
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({width: window.innerWidth})
    })
  }

	public render() {
		const {t} = this.props
		return (
			<div className="help-window">
        <>
        <div className="headline text-left" dangerouslySetInnerHTML={
          {__html: t('instructionModal.aboveHelp', {interpolation: {escapeValue: false}})}}
        >
        </div>
        <Button onClick={() => {this.props.exitHelp()}} className="close-x"><CloseOutlined /></Button>
        <Collapse 
          bordered={false} 
          defaultActiveKey={['1']}
          accordion={true}
          activeKey={convertStepIntoNumber(this.props.step)}
        >
          <Panel header={t('instructionModal.Step1.stepNumber')} key="1">
            <Row>
              <Col span={1}>
                1
              </Col>
              <Col span={23}>
                {t('instructionModal.Step1.1')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                2
              </Col>
              <Col span={23}>
                {t('instructionModal.Step1.2')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                3
              </Col>
              <Col span={23}>
                {t('instructionModal.Step1.3')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                4
              </Col>
              <Col span={23}>
                {t('instructionModal.Step1.4')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                5
              </Col>
              <Col span={23}>
                {t('instructionModal.Step1.5')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                6
              </Col>
              <Col span={23}>
               {t('instructionModal.Step1.6')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                7
              </Col>
              <Col span={23}>
                {t('instructionModal.Step1.7')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                8
              </Col>
              <Col span={23}>
               {t('instructionModal.Step1.8')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                9
              </Col>
              <Col span={23}>
                {t('instructionModal.Step1.9')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                10
              </Col>
              <Col span={23}>
                {t('instructionModal.Step1.10')}
              </Col>
            </Row>
          </Panel>
          <Panel header={t('instructionModal.Step2.stepNumber')} key="2">
            <Row>
              <Col span={1}>
                1
              </Col>
              <Col span={23}>
                {t('instructionModal.Step2.1')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                2
              </Col>
              <Col span={23}>
                {t('instructionModal.Step2.2')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                3
              </Col>
              <Col span={23}>
                {t('instructionModal.Step2.3')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                4
              </Col>
              <Col span={23}>
                {t('instructionModal.Step2.4')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                5
              </Col>
              <Col span={23}>
                {t('instructionModal.Step2.5')}
              </Col>
            </Row>
          </Panel>
          <Panel header={t('instructionModal.Step3.stepNumber')} key="3">
            <Row>
              <Col span={1}>
                1
              </Col>
              <Col span={23}>
                {t('instructionModal.Step3.1')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                2
              </Col>
              <Col span={23}>
                {t('instructionModal.Step3.2')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                3
              </Col>
              <Col span={23}>
                {t('instructionModal.Step3.3')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                4
              </Col>
              <Col span={23}>
                {t('instructionModal.Step3.4')}
              </Col>
            </Row>
            <Row>
              <Col span={1}>
                5
              </Col>
              <Col span={23}>
                {t('instructionModal.Step3.5')}
              </Col>
            </Row>
          </Panel>
        </Collapse>
            </>
        <div 
          className="video-link text-center" 
          dangerouslySetInnerHTML={
            {__html: t('instructionModal.footer', {interpolation: {escapeValue: false}})}
          }
          onClick={() => this.props.togglePlayer(true)}
        >
        </div>
      </div>
		)
	}
}

const InstructionSteps = withTranslation()(Component)

export {InstructionSteps}
