import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RouteComponentProps } from 'react-router-dom'
import { withTranslation } from "react-i18next";

import { ReduxState, getTravelTimes } from 'store'
import { TravelType } from 'enums'

import { InteractiveOverlay } from './lib'
import { Map } from '../../containers'

import axios from 'axios'
import { getToken } from 'utils/oAuth';
import { message } from 'antd'
import { convertUTCDateToLocalDate } from "../../utils/utcToLocal";
import moment from "moment";
import i18n from '../../i18n/i18next'
import { getHistory } from '../../utils/history'


interface StateProps {
	travelTimes: ReduxState['travelTime']['travelTimes']
}
interface DispatchProps {
	getTravelTimes: typeof getTravelTimes
}
interface Props { }
type PropsUnion = StateProps & DispatchProps & Props & RouteComponentProps<Params> & any

interface State {
	subscribed: boolean,
	canClickOnMap: boolean,
	isCurrentlyAddingNewTravelTime: boolean,
	instructionStepActive: string
}

interface Params {
	travelOne: string
	travelTwo: string
	travelThree: string
	travelFour: string
	travelFive: string
	travelSix: string
}

const encodingDivider = '--'

export class Component extends React.Component<PropsUnion, State> {
	public readonly state: State = {
		subscribed: false,
		canClickOnMap: false,
		isCurrentlyAddingNewTravelTime: false,
		instructionStepActive: this.props.travelTimes ? '2' : '1'
	}

	constructor(props: PropsUnion) {
		super(props)

		const params = props.match.params

		const travelsEncoded: string[] = [
			params.travelOne,
			params.travelTwo,
			params.travelThree,
			params.travelFour,
			params.travelFive,
			params.travelSix
		].filter((v) => !!v)

		if (travelsEncoded.length === 0) {
			this.updatePath()
			return
		}

		const travelsDecoded: Parameters<typeof getTravelTimes>[0] = travelsEncoded.map((encodedTravel) => {
			const [
				title,
				lat,
				lng,
				duration,
				transport
			] = encodedTravel.split(encodingDivider)

			return {
				title,
				location: {
					lat: parseFloat(lat),
					lng: parseFloat(lng)
				},
				duration: parseInt(duration, 10) * 60,
				transport: transport as TravelType
			}
		})

		props.getTravelTimes(travelsDecoded)
	}

	public componentDidUpdate(prevProps: Readonly<PropsUnion>, prevState: Readonly<State>, snapshot?: any): void {
		if ((this.props.travelTimes || (this.props.travelTimes === null && prevProps.travelTimes !== null)) && this.props.travelTimes !== prevProps.travelTimes) {
			this.props.history.replace('/')
		}

		if (this.props.travelTimes === null && prevProps.travelTimes !== null) {
			this.props.history.push('/app')
		}

		if (this.props.travelTimes && this.props.travelTimes !== prevProps.travelTimes) {
			this.updatePath()
		}
	}

	changeClickOnMap = (value: boolean) => {
		this.setState({ canClickOnMap: value })
	}

	changeisCurrentlyAddingNewTravelTime = (value: boolean) => {
		this.setState({ isCurrentlyAddingNewTravelTime: value })
	}

	changeActiveStep = (step: string) => {
		this.setState({
			instructionStepActive: step
		})
	}

	public render() {
		if (!this.state.subscribed) return null
		return (
			<Suspense fallback={(<div>Loading</div>)}>
				<Map
					changeActiveStep={this.changeActiveStep}
					instructionStepActive={this.state.instructionStepActive}
					canClickOnMap={this.state.canClickOnMap}
					changeisCurrentlyAddingNewTravelTime={this.changeisCurrentlyAddingNewTravelTime}
					isCurrentlyAddingNewTravelTime={this.state.isCurrentlyAddingNewTravelTime}
				/>
				<InteractiveOverlay
					changeActiveStep={this.changeActiveStep}
					instructionStepActive={this.state.instructionStepActive}
					changeClickOnMap={this.changeClickOnMap}
					changeisCurrentlyAddingNewTravelTime={this.changeisCurrentlyAddingNewTravelTime}
					isCurrentlyAddingNewTravelTime={this.state.isCurrentlyAddingNewTravelTime}
				/>
			</Suspense>
		)
	}

	private updatePath() {
		if (!this.props.travelTimes) return

		const path: string = this.props.travelTimes.map((travelTime) => {
			return [
				travelTime.title,
				travelTime.location.lat,
				travelTime.location.lng,
				travelTime.duration / 60,
				travelTime.transport
			].join(encodingDivider)
		}).join('/')

		this.props.history.replace('app/' + path)
	}

	componentWillUnmount(): void {
		localStorage.removeItem('lastVisited')
	}

	componentDidMount() {
		const url = `${process.env.REACT_APP_EXIPRED_CHECK_URL}subscriptions?mainProductCodes=Homelocator&showExpired=true`
		axios.get(url, { headers: { Authorization: `Bearer ${getToken()}` } })
			.then((data) => {
				const endDate = data.data.data.subscriptions[0].endDate ?
					moment(data.data.data.subscriptions[0].endDate) : moment('1995-12-25')
				const currentDate = moment(new Date().toUTCString())
				const left = endDate.diff(currentDate, 'days')
				if (left <= 14) {
					message.warn(this.props.t('Subscription will expire in days', { days: left }), 10)
				}
				this.setState({
					subscribed: true
				})
			}).catch((e) => {
				this.setState({
					subscribed: false
				})
				if (e.response) {
					if (e.response.status === 404) {
						getHistory().push(`/subscription/nosubscription?lng=${i18n.language}`)
					} else if (e.response.status === 410) {
						getHistory().push(`/buy-now?lng=${i18n.language}`)
					}
				}
			})
	}
}

const mapStateToProps = (state: ReduxState) => ({
	travelTimes: state.travelTime.travelTimes
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	getTravelTimes
}, dispatch)

const AppTranslation = withTranslation()(Component)

export const App = connect<StateProps, DispatchProps, Props, ReduxState>(
	mapStateToProps,
	mapDispatchToProps
)(AppTranslation)
