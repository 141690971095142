import axios from 'axios'

export const getCode = async (code) => {
	let statusCode
	const url = `${process.env.REACT_APP_API_BASE_URL}scratchCodes/${code}`

	const jwt = await axios.put(url).then(r => {
		statusCode = r.status
		return r.data.data.token
	}).catch(e => {
		statusCode = e.response.status
	})

	const redirect_uri = `${process.env.REACT_APP_ORIGIN}/app`

	const identityUrl = `${process.env.REACT_APP_AUTH_URL}TokenLogin?jwt=${jwt}&redirect_uri=${redirect_uri}`

	// window.location.href = identityUrl

	return { statusCode, identityUrl }
}

export const checkEmail = async (email) => {
	const url = `${process.env.REACT_APP_API_BASE_URL}/accounts/${encodeURIComponent(email)}/available`
	const response = await axios.get(url).then(r => {
		return r.status
	}).catch(e => {
		return e.response.status
	})

	return response
}

export const registerUser = async (data) => {
	const url = `${process.env.REACT_APP_API_BASE_URL}/accounts`
	const response = await axios.post(url, data).then(r => {
		if (r.status === 201) {
			window.location.href = '/account-created'
		} 
	}).catch(e => {
		return e.response.status
	})

	return response
}
