import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { OverlayView, Marker, InfoWindow } from 'react-google-maps'
import styled, { css } from 'styled-components'

import { ReduxState, setGeoJsonDataMarkers, setLastCheckedInfoWindowId, setZoomLevel } from 'store'
import { colorList, getTravelTypeInfo } from 'utils'
import { TravelType } from 'enums/TravelType'
import { InfoIcon } from 'icons'


const RightDiv = styled.div`
	display: flex;
	justify-content: flex-end;
	vertical-align: middle;

	h3 {
		line-height: 6px;
		padding-left: 0.25rem;
		padding-top: 13px;
		cursor: pointer;
	}
`

const InfoDiv = styled.div`
	margin-left: 1rem;	
	margin-right: 1rem;
`

const StyledMarker = styled.div<{ color: string, minimalStyle: boolean }>`
	position: relative;
	width: ${(props) => props.minimalStyle ? '.5rem' : '2rem'};
	height: ${(props) => props.minimalStyle ? '.5rem' : '2rem'};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 99px;
	background-color: ${(props) => props.color};
	color: #fff;
	z-index: 0;
	
	${(props) => props.minimalStyle && css`
		& > * {
			opacity: 0;
		}
	`};
	
	&:before, &:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 99px;
		background-color: ${(props) => props.color};
		z-index: -1;
	}
	
	&:before {
		transform: ${(props) => props.minimalStyle ? 'scale(2.5)' : 'scale(1.8)'};
		opacity: .5;
	}
	
	&:after {
		transform: ${(props) => props.minimalStyle ? 'scale(4)' : 'scale(3)'};
		opacity: .25;
	}
`
const StyledTravelCardActionItem = styled.div`
	cursor: pointer;
	position: relative;
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, .1);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 0.5rem;
	
	:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, .1);
		border-radius: 50%;
		width: 100%;
		height: 100%;
		transition: 80ms;
	}
	:hover:before {
		transform: scale(1.25);
	}
	
	svg {
		max-width: 1rem;
		max-height: 1rem;
	}
	
	&:not(:last-child) {
		margin-bottom: .5rem;
	}
`

interface StateProps {
	travelTimes: ReduxState['travelTime']['travelTimes']
	overlapVisible: ReduxState['application']['overlapVisible']
	geoJsonDataMarkers: ReduxState['application']['geoJsonDataMarkers']
	legend: ReduxState['application']['legend']
	zoom: ReduxState['application']['zoom']
	lastCheckedId: ReduxState['application']['lastInfoWindowId']
	bounds: ReduxState['application']['bounds']
}
interface DispatchProps {
	setGeoJsonDataMarkers: typeof setGeoJsonDataMarkers,
	setLastCheckedInfoWindowId: typeof setLastCheckedInfoWindowId
	setZoomLevel: typeof setZoomLevel
}
interface Props { }
type PropsUnion = StateProps & DispatchProps & Props & any

interface State {
	markers: any
}

export class Component extends React.Component<PropsUnion, State> {
	public readonly state: State = {
		markers: []
	}

	public render() {
		const { travelTimes, overlapVisible, geoJsonDataMarkers, legend, zoom, t } = this.props
		var markersToShow = [];
		if (zoom <= 13 && geoJsonDataMarkers !== null && geoJsonDataMarkers['features']){
			geoJsonDataMarkers['features'].forEach(m => {
				if (m.properties['marker-type'] === 'group')
					markersToShow.push(m)
			})
		}
		if (zoom > 13 && geoJsonDataMarkers != null && geoJsonDataMarkers['features']) {
			geoJsonDataMarkers['features'].map((feature) => {
				if (legend.includes(feature['properties']['marktwaardeKlasse'])) {
					if (feature['id'] != this.props.lastCheckedId) {
						feature['properties']['open'] = false;
					}

					if (this.props.bounds && this.props.bounds.contains({ lat: feature['geometry']['coordinates'][1], lng: feature['geometry']['coordinates'][0] })) {
						markersToShow.push(feature);
					}
				}
			});
		}
		this.state.markers = markersToShow;
		if (markersToShow.length > 0) {
			return (
				<>

					{travelTimes && travelTimes.map((travelTime, i) => (
						<OverlayView
							key={i}
							position={travelTime.location}
							mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
							getPixelPositionOffset={(width, height) => ({
								x: -(width / 2),
								y: -(height / 2)
							})}
						>
							<StyledMarker color={colorList[i]} minimalStyle={overlapVisible}>
								{getTravelTypeInfo(travelTime.transport).icon}
							</StyledMarker>
						</OverlayView>
					))}
					{
						markersToShow.map((feature, i) => (
							<Marker
								key={feature['id']}
								position={{ lat: feature['geometry']['coordinates'][1], lng: feature['geometry']['coordinates'][0] }}
								options={
									feature.properties['marker-type'] === 'group' ? 
									{
										icon: `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${feature.properties['group-size'].toString()}|${feature['properties']['marker-color'].substr(1)}|ffffff`
									}
									:
									{
										icon: {
											path: 'M20,13.8c0,5.5-4.4,9.3-10,14.2C4.4,23.1,0,19.3,0,13.8C0,8.4,4.5,4,10,4S20,8.4,20,13.8z',
											fillOpacity: 2,
											fillColor: feature['properties']['marker-color'],
											strokeWeight: 1,
											strokeColor: 'black',
											anchor: new google.maps.Point(10, 27)
										}
									}
								}
								onClick={() => this.handleMarkerTap(feature)}
								// label={feature.properties['marker-type'] === 'group' ?
								//  feature.properties['group-size'].toString() : null}
								clickable={true}
								// clickable={feature.properties['marker-type'] === 'group' ? false : true} 
							>
								{
									feature['properties']['open'] ?
										<InfoWindow
											onCloseClick={() => this.handleMarkerTap(feature)}>
											<InfoDiv>
												<h3 className="property_headline">{feature['properties']['adres']}</h3>
												<div className="img_status">
														<div className="status">
															<span>
																{feature['properties']['status']}
															</span>
														</div>
														<img src={feature['properties']['pictureUri']}
															onClick={() => {
																const win = window.open(feature['properties']['detailsUri'], '_blank');
																if (win != null) {
																	win.focus();
																}
															}}
														/>
												</div>
												<h4>{feature['properties']['buurtNaam']}</h4>
												<h4 className="price">{
													feature['properties']['status'] === 'aktief' ?
													new Intl.NumberFormat('nl-NL', {
														style: 'currency',
														currency: 'EUR',
														maximumFractionDigits: 0,
														minimumFractionDigits: 0,
													}).format(feature['properties']['vraagprijs'])
													: ""
												}
												{feature['properties']['status'] === 'aktief' && feature['properties']['prijssoort'] ?
													` (${feature['properties']['prijssoort']})` : ''
												}
												</h4>
												<span>
													{feature['properties']['makelaar'] ?
														feature['properties']['makelaar'] : ''
													}
												</span>
												<RightDiv onClick={() => {
														const win = window.open(feature['properties']['detailsUri'], '_blank');
														if (win != null) {
															win.focus();
														}
													}}>
													<StyledTravelCardActionItem>
														<InfoIcon />
													</StyledTravelCardActionItem>
													<h3>Details</h3>
												</RightDiv>
											</InfoDiv>
										</InfoWindow>
										:
										''
								}
							</Marker>
						))
					}
				</>
			)
		} else {
			return (
				<>
					{travelTimes && travelTimes.map((travelTime, i) => (
						<OverlayView
							key={i}
							position={travelTime.location}
							mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
							getPixelPositionOffset={(width, height) => ({
								x: -(width / 2),
								y: -(height / 2)
							})}
						>
							<StyledMarker color={colorList[i]} minimalStyle={overlapVisible}>
								{getTravelTypeInfo(travelTime.transport).icon}
							</StyledMarker>
						</OverlayView>
					))}
				</>
			)
		}
	}

	public changeIcon = (color) => {
		return {
			fillColor: color
		}
	}

	public handleMarkerTap(feature) {
		if (feature.properties['marker-type'] === 'group') {
			this.props.changeZoom(14)
			this.props.changeCenter({
				lat: feature.geometry.coordinates[1],
				lng: feature.geometry.coordinates[0]
			})
			this.props.onZoomMap()
		}
		const newMarkers = this.state.markers.map((marker) => {
			if (feature['id'] == marker['id']) {
				if (marker['properties']['open'] === false) {
					marker['properties']['open'] = true
				} else if (marker['properties']['open'] === true) {
					marker['properties']['open'] = false
				}
			}
			return marker
		})
		this.props.setLastCheckedInfoWindowId(feature['id']);
		this.setState({
			markers: newMarkers
		})
	}

	// public shouldComponentUpdate(nextProps: Readonly<PropsUnion>, nextState: Readonly<State>, nextContext: any): boolean {
	// 	return true
	// }
}

const mapStateToProps = (state: ReduxState) => ({
	travelTimes: state.travelTime.travelTimes,
	overlapVisible: state.application.overlapVisible,
	geoJsonDataMarkers: state.application.geoJsonDataMarkers,
	legend: state.application.legend,
	zoom: state.application.zoom,
	lastCheckedId: state.application.lastInfoWindowId,
	bounds: state.application.bounds
})
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	setGeoJsonDataMarkers,
	setLastCheckedInfoWindowId,
	setZoomLevel
}, dispatch)

export const Markers = connect<StateProps, DispatchProps, Props, ReduxState>(
	mapStateToProps,
	mapDispatchToProps
)(Component)
