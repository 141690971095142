import React from 'react'
import {Row, Col, Card, Form, Button, Input } from 'antd'
import {LogoIcon, MvaIcon, ClearstoneIcon, MvaLogo} from 'icons'
import styled from 'styled-components'
import {withTranslation} from 'react-i18next'
// tslint:disable-next-line: no-submodule-imports
import 'antd/lib/message/style'
import i18n from "../../../i18n/i18next";
import { withCookies } from 'react-cookie';
import queryString from "query-string";
import { confirmEmail } from "../../../utils/confirmEmail";
import {getHistory} from '../../../utils/history'
import axios from 'axios'


const CenteredDiv = styled.div`
	position: absolute;
`

const MarginSpan = styled.span`
margin: 0px 10px
`

const StyledLogoContainer = styled.div`
	z-index: 10;
`
const StyledLogoContainerIndividual = styled.div`	
	z-index: 10;
	margin-left:10rem;
	padding-left:10rem;
`

interface State {
}

const validateMessages = {
  types: {
    email: '${name} is not a valid'
  }
}

class Component extends React.Component<any, State> {

	form = React.createRef()

	componentDidMount(){document.body.style.overflow='auto'}
	componentWillUnmount(){document.body.style.overflow='hidden'}

	public render() {
		const {t} = this.props
		const params = queryString.parse(this.props.location.search)
		return (
			<CenteredDiv>
				<Row gutter={[16, 16]} justify="center" align="top">
					<Col sm={24} md={12} lg={12} xl={8} className="text-center login-col">
						<Card className="login-card email">
							<div className="login-logo">
								<MvaLogo/>
							</div>
							<div className="language-buttons">
								<Button className="white" onClick={() => this.changeLng('en')}><span >en</span></Button>
								<Button className="white" onClick={() => this.changeLng('nl-NL')}><span >nl</span></Button>
							</div>
							<h2 className="green">{t('confirmEmailPage.title')}</h2>
							<p className="text-left" dangerouslySetInnerHTML={
									{__html: t('confirmEmailPage.mainText', {interpolation: {escapeValue: false}})}
							}></p>
							<div className="email-form">
								<Form
									labelCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
									wrapperCol={{ sm: 24, md: 24, lg: 24, xl: 24 }}
									initialValues={{
										Email: params.email ? params.email : '',
									}}
									layout="vertical"
									size='middle'
									onFinish={this.onFinish}
									validateMessages={validateMessages}
								>
									<Form.Item
										label={t('confirmEmailPage.emailLabel')}
										className="form-center"
										name="Email"
										rules={[
											{ required: true, message: `${t('confirmEmailPage.emailRequired')}`},
											{type: 'email', message: `${t('confirmEmailPage.emailInvalid')}` }]}
									>
										<Input type="email"/>
									</Form.Item>
									<Form.Item
										className="login-buttons btn-submit"
									>
										<Button type="primary" className="blue" htmlType="submit">
											{t('confirmEmailPage.submit')}
										</Button>
									</Form.Item>
								</Form>
							</div>
						</Card>
						<div className="login-logos">
							<span>{t('Homelocator brought to you by')}:</span>
							<StyledLogoContainer>
								<MvaIcon />
								<LogoIcon/>
								<ClearstoneIcon/>
							</StyledLogoContainer>
							<span 
								style={{'fontWeight': 'normal', 'textTransform': 'none', 'fontSize': '12px'}}
								dangerouslySetInnerHTML={
									{__html: t('Based on the open-source MapitOut application', {interpolation: {escapeValue: false}})}
								}
								></span>
						</div>
					</Col>
				</Row>
			</CenteredDiv>
		)
	}

	private changeLng = (lng) => {
	  i18n.changeLanguage(lng)
  }

	private onFinish = (values) => {
		const url = `${process.env.REACT_APP_API_BASE_URL}users/confirmaccount`
		axios.post(url, {
			emailAddress: values.Email,
			locale: i18n.language === 'en' ? 'en-GB' : 'nl-NL'
			})
			.then(r => {
				getHistory().push(`/confirm-account/success?lng=${i18n.language}`)
			})
			.catch(e => {
				if (e.response.status == 405) {
					getHistory().push(`/confirm-account/already-confirmed?lng=${i18n.language}`)
				} else {
					getHistory().push(`/confirm-account/fail?lng=${i18n.language}`)
				}
			})
	}
}

const MailCookies = withCookies(Component) as any

const MailPage = withTranslation()(MailCookies)

export {MailPage}
