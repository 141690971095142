import axios from 'axios'
import { getToken } from '../utils/oAuth'

export const api = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	timeout: 240000,
	headers: {
		Authorization: `Bearer ${getToken()}`
	}
})
