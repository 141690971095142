import React, { Component } from 'react'

import AuthService from '../../utils/oAuth'
import i18n from '../../i18n/i18next'

const OidcContext = React.createContext({
	signinRedirectCallback: () => ({}),
	logout: () => ({}),
	signoutRedirectCallback: () => ({}),
	isAuthenticated: () => ({}),
	signinRedirect: () => ({}),
	signinSilentCallback: () => ({}),
	createSigninRequest: () => ({})
});

export const OidcConsumer = OidcContext.Consumer

// @(connect() as any)
class OidcComponent extends Component {

	// eslint-disable-next-line react/sort-comp
	public authService

	constructor(props) {
		super(props)
		this.authService = new AuthService(props)
		this.authService.UserManager.settings._ui_locales = i18n.language === 'en' ? 'en-GB' : 'nl-NL'
		i18n.on('languageChanged', (lng) =>{
			this.authService.UserManager.settings._ui_locales = lng === 'en' ? 'en-GB' : lng
		})
	}

	public render() {
		// eslint-disable-next-line react/destructuring-assignment
		return <OidcContext.Provider value={this.authService}>{this.props.children}</OidcContext.Provider>;
	}

}

export default OidcComponent;
